import React, { ReactElement } from 'react';
import Table from 'react-bootstrap/Table';
import { redAsterisk } from '../SharedComponents/RedAsterisk';
import { TagValue } from './TagValue';
import { ValidationErrors } from './ValidationErrors';
import { EditorProps, TaggingKeyRule, TagKeyPair } from './types';
import { applyCaseRule } from './util';

function isValidateObject(val: undefined | boolean | { [key: string]: boolean }): val is { [key: string]: boolean } {
  if (!val || typeof(val) === 'boolean') {
    return false;
  } else {
    return Boolean(val);
  }
}

export const Editor: React.FC<EditorProps> = ({
  standard,
  onTagChange,
  tags = {},
  validate,
  errors = {} ,
  onBlur,
  validationResponse,
}: EditorProps): ReactElement => {

  const tagChangeHandler = (rule: TaggingKeyRule) => (tag: TagKeyPair) => {
    if (onTagChange) {
      const finalTag = Object.assign({}, tag, {
        value: applyCaseRule(rule.case_rule, tag.value),
      });
      onTagChange(finalTag);
    }
  };
  return (
    <div>
      <Table bordered striped>
        <thead>
          <tr>
            <th>tag key</th>
            <th>tag value</th>
          </tr>
        </thead>
        <tbody>
          {standard.rules.key_rules.map(rule => {
            const tag = tags[rule.tag_key];
            const validateTag = isValidateObject(validate) ? validate[rule.tag_key] : validate;
            return (
              <tr key={rule.tag_key}>
                <td>
                  {rule.tag_key} {rule.required ? redAsterisk : ''}
                </td>
                <td>
                  <TagValue
                    rule={rule}
                    tag={tag}
                    validate={validateTag}
                    errors={errors[rule.tag_key]}
                    onChange={tagChangeHandler(rule)}
                    onBlur={() => onBlur && onBlur(tag)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {validationResponse && <ValidationErrors validationData={validationResponse} />}
    </div>
  );
};
