import React from 'react';
import _ from 'lodash';
import Alert from 'react-bootstrap/Alert';

interface CheckForNullProps {
  target: any;
  field: any;
  errorMessage: string;
}

export default class CheckForNull extends React.Component<CheckForNullProps, {}> {
  render(): React.ReactNode {
    if (_.get(this.props.target, this.props.field, null) === null) {
      return (
        <Alert variant="danger">
          <Alert.Heading>
            <strong>Error!</strong>
          </Alert.Heading>
          {this.props.errorMessage}
        </Alert>
      );
    } else {
      return this.props.children;
    }
  }
}
