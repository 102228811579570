import React, { Component } from 'react';

interface Account {
  name: string;
  accountType: string;
}

interface AccountTitleProps {
  account: Account;
}

export class AccountTitle extends Component<AccountTitleProps> {
  render(): React.ReactNode {
    const { account } = this.props;
    return (
      <div className="mt-2">
        <h1>
          {account.name} - {account.accountType}
        </h1>
      </div>
    );
  }
}
