import _ from 'lodash';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { EditorTags, ErrorsByTag, TaggingStandard, TagKeyPair, TaggingKeyRule, TagValidationResponse } from './types';

export function tagsFromStandard(standard: TaggingStandard) {
  return standard.rules.key_rules.reduce((res: { [key: string]: TagKeyPair }, rule) => {
    res[rule.tag_key] = {
      key: rule.tag_key,
      value: undefined,
    }
    return res;
  }, {});
}

export function applyCaseRule(caseRule: string | undefined, val: string | undefined): string | undefined {
  if (!caseRule || !val) {
    return val;
  } else if (caseRule === 'lower') {
    return val.toLowerCase();
  } else if (caseRule === 'upper') {
    return val.toUpperCase();
  } else {
    throw new Error(`Unsupported case rule: ${caseRule}`);
  }
}

export function isIllegal(illegalStrings: string[] | undefined, s: string | undefined): boolean {
  if (!illegalStrings) {
    return false;
  } else {
    return illegalStrings.includes(s as string);
  }
}

export function validateRule(rule: TaggingKeyRule, tag: TagKeyPair): string[] {
  const errors = [] as string[];
  if (rule.required && _.isEmpty(tag.value)) {
    errors.push(`'${tag.key}' is a required tag`);
  } else if (isIllegal(rule.illegal_strings, tag.value)) {
    errors.push(`'${tag.value}' is not allowed as a tag value`);
  } else if (!_.isEmpty(tag.value) && rule.value_list && !rule.value_list.includes(tag.value as string)) {
    errors.push(`'${tag.value}' not in value list`);
  }
  return errors;
}

export function validateTags(standard: TaggingStandard, tags: EditorTags): ErrorsByTag {
  const errors = {} as ErrorsByTag;
  standard.rules.key_rules.forEach((rule: TaggingKeyRule) => {
    const tag = tags[rule.tag_key];
    const tagErrors = validateRule(rule, tag);
    if (tagErrors && tagErrors.length) {
      errors[rule.tag_key] = tagErrors;
    }
  });
  return errors;
}

export interface ValidateTagsFromServerArgs {
  apollo: ApolloClient<any>;
  tags: EditorTags;
}

export const validationQuery = gql`
  query ValidateTags($tags: [TagInput!]!) {
    validateTags(standard: "FY21-TaggingStandard", tags: $tags) {
      code
      message
      status
    }
  }
`;

export function asTagList(tags: EditorTags): TagKeyPair[] {
  return Object.values(tags)
    .filter(t => !_.isEmpty(t.value))
    .map(t => _.pick(t, ['key', 'value']));
}

export async function validateTagsFromServer(args: ValidateTagsFromServerArgs): Promise<TagValidationResponse> {
  const { tags, apollo } = args;
  const { data } = await apollo.query({
    query: validationQuery,
    errorPolicy: 'all',
    variables: {
      tags: asTagList(tags),
    },
  });
  return data.validateTags;
}
