import React from 'react';

export interface DocLinkProps {
  href: string;
  label?: string;
}

export const DocLink: React.FC<DocLinkProps> = ({ href, label }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {label || href}
  </a>
)

export const foundationsBenchmarkLink = (
  <a href="https://www.cisecurity.org/benchmark/alibaba_cloud/" target="_blank" rel="noopener noreferrer">
    Center for Internet Security AliCloud Foundations Benchmark
  </a>
);

export const infoSecurityProgramLink = (
  <a href="http://nisp.nike.com/" target="_blank" rel="noopener noreferrer">
    Nike Information Security Program
  </a>
);

export const departmentLookupLink = (
  <a
    href="https://confluence.nike.com/display/CCOE/Cloud+Executive+Sponsors+and+Departments"
    target="_blank"
    rel="noopener noreferrer"
  >
    https://confluence.nike.com/display/CCOE/Cloud+Executive+Sponsors+and+Departments
  </a>
);

export const waffleIronFaqLink = (
  <a href="https://confluence.nike.com/x/Kun2Dg" target="_blank" rel="noopener noreferrer">
    Waffle Iron FAQ
  </a>
);

export const enterpriseCloudGovernanceLink = (
  <a
    href="https://confluence.nike.com/display/ECG/Enterprise+Cloud+Governance"
    target="_blank"
    rel="noopener noreferrer"
  >
    Enterprise Cloud Governance
  </a>
);

export const waffleBarLink = (text: string): React.ReactNode => (
  <a href="https://github.com/nike-waffle/wafflebar" target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export const npeLink = (text: string): React.ReactNode => (
  <a href="https://docs.platforms.nike.com/NPE" target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export const slackLink = (channel: string): React.ReactNode => DocLink({
  href: `https://slack.com/app_redirect?channel=${channel}`,
  label: `#${channel}`,
});
