import React  from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import '../AccountList/Accounts.scss';
import _  from 'lodash';

import './MyAccounts.scss';
import StatsCard from "../Metrics/StatsCard";
import AccountList from "../Metrics/AccountList";

export interface MyAccountDashboardProps {
  accountRoles: any;
  contacts: any;
  userAccessAccounts: any;
  userContactAccounts: any;
  removeLinks?: boolean; // StoryBook fails when using Link tags outside of a Router
}

interface MyAccountsQueryResponse {
  data: any;
}

const GetMyAccountMetadata = gql`
  query GetMyAccounts {
    currentUser {
      contacts {
        id
      }
      accountRoles {
        role
      }
      accountsWithUserAsContact {
        contactId
        accountType
        name
        status
        accountId
        lineage
        partition
        cloudServiceProvider
        userContactTypes
      }
      accountsWithAccess {
        contactId
        accountType
        name
        status
        accountId
        lineage
        partition
        cloudServiceProvider
        userContactTypes
        userRolesAccess
      }
    }
  }
`;

const withMyAccounts = withGraphQuery(GetMyAccountMetadata, {
  options: () => ({
    errorPolicy: 'all',
  }),
});

const getAccountsForContactType = (data: any, contactType: string) => {
  return _.chain(data)
    .map('userContactTypes')
    .filter(types => types.includes(contactType))
    .value();
}

export const DefaultMyAccountsDashboard = (props: MyAccountDashboardProps) => {
  const { accountRoles, contacts, userAccessAccounts, userContactAccounts, removeLinks } = props;
  const roleCounts = _.countBy(accountRoles, "role");
  const cspCounts = _.countBy(userAccessAccounts, "cloudServiceProvider");
  const totalContacts = contacts ? contacts.length : 0;

  return (
    <div className="my-accounts-container">
      <div className="my-accounts-stats-container">
        <div className="my-accounts-stats-header">
          <h2>My Accounts</h2>
        </div>
        {
          _.map(cspCounts, (value, key) =>
            <StatsCard title={`${key} Access`} value={value} />
          )
        }
        {
          _.map(roleCounts, (value, key) =>
            <StatsCard title={key} value={value} />
          )
        }
        <StatsCard title="Total Contacts" value= {totalContacts} />
        {
          _.map(["Billing", "Business", "Security", "Technical"], type =>
            <StatsCard title={`${type} Contact`} value={getAccountsForContactType(userContactAccounts, type).length} />
          )
        }
      </div>
      <div className="my-accounts-lists-container">
        <AccountList
          cardTitle={"Accounts with Me as Contact"}
          accounts={userContactAccounts}
          columnFields={["accountId", "name", "accountType", "status", "userContactTypes"]}
          removeLinks={removeLinks}
        />
        <AccountList
          cardTitle={"Accounts with Login Access"}
          accounts={userAccessAccounts}
          columnFields={["accountId", "name", "accountType", "cloudServiceProvider", "userRolesAccess"]}
          numPerPage={8}
          removeLinks={removeLinks}
        />
      </div>
    </div>
  )
}

export default withMyAccounts((resp: MyAccountsQueryResponse) => {
  const data = _.get(resp, 'data.currentUser');
  return (<DefaultMyAccountsDashboard
    accountRoles = {_.get(data, 'accountRoles')}
    contacts = {_.get(data, 'contacts')}
    userAccessAccounts = {_.get(data, 'accountsWithAccess')}
    userContactAccounts = {_.get(data, 'accountsWithUserAsContact')}
  />);
});
