import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { LoggerFactory } from '../../util/LoggerFactory';
import JobStatus from '../AccountDetail/JobStatus';

const log = LoggerFactory.getLogger('Create Account Status');

interface CreateAccountStatusPureProps {
  jobStatuses: any[];
}

const tableColumns = [
  {
    dataField: 'status',
    text: 'Creation status',
    formatter: (status: string, job: any) => {
      if (job.steps) {
        return <JobStatus steps={job.steps} status={job.status} error={job.error} jobId={job.jobId} />;
      } else {
        return status;
      }
    },
  },
  {
    dataField: 'jobId',
    text: 'Request',
  },
  {
    dataField: 'createAccountJobId',
    text: 'Create Job',
  },
  // {
  //   dataField: 'contactId',
  //   text: 'Contact',
  //   formatter: (contactId: any) => {
  //     return contactId ? <Link to={`/contact/${contactId}`}>{contactId}</Link> : '';
  //   },
  // },
  // {
  //   dataField: 'tagsetId',
  //   text: 'Tagset',
  //   formatter: (tagsetId: any) => {
  //     return tagsetId ? tagsetId : '';
  //   },
  // },
  {
    dataField: 'accountId',
    text: 'Account',
    formatter: (accountId: any) => {
      return accountId ? <Link to={`/account/${accountId}`}>{accountId}</Link> : '';
    },
  },
];

interface ErrorsTableProps {
  errors: any[];
}

export const errorColumns = [
  {
    dataField: 'jobId',
    text: 'Job ID',
  },
  {
    dataField: 'component',
    text: 'Request component',
  },
  {
    dataField: 'message',
    text: 'Message',
  },
];

export class ErrorsTable extends React.Component<ErrorsTableProps, {}> {
  render(): React.ReactNode {
    return (
      <div>
        <div>Account creation errors</div>
        <BootstrapTable columns={errorColumns} data={this.props.errors} keyField="jobId" />
      </div>
    );
  }
}

export class CreateAccountStatusTablePure extends React.Component<CreateAccountStatusPureProps, {}> {
  render(): React.ReactNode {
    log.debug('props:', this.props);
    const { jobStatuses } = this.props;

    const errors = [].concat.apply(
      [],
      (jobStatuses || []).map((status: any) =>
        (status.errors || []).map((err: any) => ({ ...err, jobId: status.jobId }))
      )
    );

    return (
      <div>
        <div>Account Creation Status</div>
        <BootstrapTable columns={tableColumns} data={jobStatuses || []} keyField="jobId" />
        <ErrorsTable errors={errors || []} />
      </div>
    );
  }
}

export default CreateAccountStatusTablePure;
