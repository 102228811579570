import React from 'react';
import { Table, Button, Row, Col, Modal } from 'react-bootstrap';
import _ from 'lodash';
import HasAccess from '../SharedComponents/HasAccess';
import ApiError from '../SharedComponents/ApiError';
import TagSetEditor from './TagSetEditor';
import { Link,Prompt } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSync, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import JobStatus from './JobStatus';
import Date from './Date';
import { renderTableData } from '../SharedComponents/MissingAttribute';
import { renderContact } from '../Contacts/ContactEditor';
import DisabledContact from '../Contacts/DisabledContact';
import CostAllocation from './CostAllocation';
import { withAccountClient, WithAccountClientProps } from '../Clients/AccountClient';
import FeatureEditor from '../Features/FeatureEditor';
import './AccountEditor.scss';
import { LoggerFactory } from '../../util/LoggerFactory';
import { AccountTitle } from './AccountTitle';
import { TerminationProtectionToggle } from './TerminationProtectionToggle';
import moment from 'moment';

const log = LoggerFactory.getLogger('Account Editor');

function renderContactLink(contactId: string, accountId: string): React.ReactNode {
  return (
    <Link to={`/contact/${contactId}/${accountId}`}>
      <FontAwesomeIcon icon={faEdit} />
    </Link>
  );
}

function isDeleteJobFailed(job: any): boolean {
  return Boolean(_.get(job, ['error', 'code']));
}

function isDeletableAccount(account: any): boolean {
  const deleteJobs: any[] = (account.jobs || []).filter((j: any) => j.type === 'DELETE');
  return (
    account.lineage === 'WAFFLE_IRON' &&
    account.cloudServiceProvider !== 'GCP' &&
    !account.terminationProtection &&
    deleteJobs.every(isDeleteJobFailed)
  );
}

interface AccountEditorProps extends WithAccountClientProps {
  account: any;
  refresh: () => void;
}

interface AccountEditorState {
  isSaving: boolean;
  mutateAccount: any;
  error?: object;
  isModalOpen: boolean;
  preventNav:boolean;
}

function sandboxExpiration(account: any): any {
  if (account.accountType === 'SANDBOX' && account.lineage === 'WAFFLE_IRON') {
    const parsedDate = moment(account.expirationDate, moment.ISO_8601, true);
    const expires = parsedDate.isAfter(moment.now()) ? 'Expires' : 'Expired';
    return (
      <div>
        {expires} <Date date={account.expirationDate} />
      </div>
    );
  } else {
    return null;
  }
}

export class AccountEditor extends React.Component<AccountEditorProps, AccountEditorState> {
  state = {
    isSaving: false,
    mutateAccount: this.props.account,
    error: undefined,
    isModalOpen: false,
    preventNav:false
  };

  updateAccount(mutateAccount: object): void {
    this.setState({
      mutateAccount: {
        ...this.state.mutateAccount,
        ...mutateAccount,
      },
      preventNav:true
    });
  }

  saveAccount = async (): Promise<void> => {
    log.debug('saving account');
    this.setState({ isSaving: true ,preventNav:false});
    try {
      const response = await this.props.accountClient.saveAccount(
        this.props.account.accountId,
        this.state.mutateAccount
      );
      log.debug(response);
      if (response.status >= 400) {
        const body = await response.json();
        return this.setState({ error: body, isSaving: false });
      }

      this.setState({ isSaving: false });
      this.props.refresh();
    } finally {
      this.setState({ isSaving: false });
    }
  };

  refreshAccount = async (): Promise<void> => {
    log.debug('saving account');
    this.setState({ isSaving: true,preventNav:false });
    try {
      const response = await this.props.accountClient.saveAccount(this.props.account.accountId, {});
      log.debug(response);
      if (response.status >= 400) {
        const body = await response.json();
        return this.setState({ error: body, isSaving: false });
      }

      this.setState({ isSaving: false });
      this.props.refresh();
    } finally {
      this.setState({ isSaving: false });
    }
  };

  openAccountDeletionConfirmationModal = (): void => {
    this.setState({ isModalOpen: true });
  };

  closeAccountDeletionConfirmationModal = (): void => {
    this.setState({ isModalOpen: false });
  };

  requestAccountDeletion = async (): Promise<void> => {
    this.closeAccountDeletionConfirmationModal();
    try {
      const response = await this.props.accountClient.requestAccountDeletion(this.props.account.accountId);
      log.debug(response);
      if (response.status >= 400) {
        const body = await response.json();
        return this.setState({ error: body, isSaving: false });
      }

      this.setState({ isSaving: false });
      this.props.refresh();
    } finally {
      this.setState({ isSaving: false });
    }
  };

  isEditable = () => {
    // verify the account has everything we require to run the workflow
    const contact = this.props.account.contact;
    if (!contact) {
      return false;
    }
    // the contact must be active
    if (!contact.active) {
      return false;
    }
    // there must be a technical contact present
    if (contact.technical && !contact.technical.primary.name) {
      return false;
    }
    return true;
  };

  render(): React.ReactNode {
    const account = this.props.account;
    const { mutateAccount, isModalOpen, preventNav} = this.state;
    const deleteAllowed = isDeletableAccount(account);
    const showProgress =
      account.status === 'UPDATING' && account.currentJobs !== null && account.currentJobs.length === 1;
    return (
      <HasAccess hasAccess={_.get(account, 'permissions.hasAccess', false)}>
        <Modal
          show={isModalOpen}
          backdrop="static"
          keyboard={false}
          onHide={this.closeAccountDeletionConfirmationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Danger Zone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you wish to delete the account? You are responsible for removing any resources you may have
              deployed in the account. If there are still resources in the account, the deletion will fail.
            </p>
            <p>
              After requesting account deletion, a DELETE job will be created in the "Event History" tab and a Waffle
              Iron admin will complete the deletion request at a later date. When this occurs, the account status will
              change to "DELETING". You can track the progress of the request by clicking the "DETAILS" button on the
              deletion job.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeAccountDeletionConfirmationModal}>
              Cancel
            </Button>
            <Button variant="danger" id="confirmDeleteAccountBtn" onClick={this.requestAccountDeletion}>
              Request Account Deletion... <FontAwesomeIcon icon={faExclamationTriangle} />
            </Button>
          </Modal.Footer>
        </Modal>
        <Prompt
          when={preventNav}
          message={location =>
            `Are you sure you want leave this page without saving?`
          }
        />
        <div className="account-details-container">
          <ApiError error={this.state.error} />
          <div className="account-details-header">
            <AccountTitle account={account} />
            <HasAccess.Body>
              <div className="account-actions-container">
                <Button
                  disabled={!deleteAllowed || !this.isEditable()}
                  className="offset-sm-1"
                  id="deleteAccountBtn"
                  variant="danger"
                  onClick={this.openAccountDeletionConfirmationModal}
                >
                  Delete Account
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </Button>
                <Button
                  disabled={this.state.isSaving || !this.isEditable()}
                  className="offset-sm-1"
                  id="refreshAccountBtn"
                  variant="primary"
                  onClick={this.refreshAccount}
                >
                  Account Refresh
                  <FontAwesomeIcon icon={faSync} />
                </Button>
                <Button
                  id="saveAccountBtn"
                  onClick={this.saveAccount}
                  variant={this.state.isSaving || _.isEmpty(this.state.mutateAccount) ? 'secondary' : 'success'}
                  disabled={ this.state.isSaving || _.isEmpty(this.state.mutateAccount) || !this.isEditable() || !this.state.preventNav}>
                  {this.state.isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </HasAccess.Body>
          </div>
          <div className="account-details">
            <Row>
              <Col xs={12} lg={6}>
                <h2 className="account-features-header">Account Details</h2>
                <Table bordered striped>
                  <tbody>
                    <tr>
                      <th>AccountId</th>
                      <td>{account.accountId}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>
                        {account.accountType}
                        {sandboxExpiration(account)}
                      </td>
                    </tr>
                    <tr>
                      <th>Lineage</th>
                      <td>{account.lineage}</td>
                    </tr>
                    <tr>
                      <th>Partition</th>
                      <td>{account.partition}</td>
                    </tr>
                    <tr>
                      <th>Cloud Service Provider</th>
                      <td>{account.cloudServiceProvider}</td>
                    </tr>
                    <tr>
                      <th>Created Date</th>
                      <td>
                        <Date date={account.creationDate} />
                      </td>
                    </tr>
                    <tr>
                      <th>Updated Date</th>
                      <td>
                        <Date date={account.updatedDate} />
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        {account.status} {showProgress && <JobStatus {...account.currentJobs[0]} hideStatus />}
                      </td>
                    </tr>
                    <tr>
                      <th>Termination Protection</th>
                      <td>
                        <TerminationProtectionToggle
                          value={mutateAccount.terminationProtection || false}
                          onValueChange={(v: boolean) => this.updateAccount({ terminationProtection: v })}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>TagSet ID</th>
                      <td>
                        {
                          <TagSetEditor
                            tagset={account.tagset || { id: '', tags: [] }}
                            onChange={(value: string | null) => this.updateAccount({ tagging: { tagGuid: value } })}
                          />
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <CostAllocation
                  {...account.costAllocation}
                  onChange={(value: string | null) => this.updateAccount({ costAllocationId: value })}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="account-contact-details">
                  <h2>Contacts
                    <HasAccess.Body>
                      {renderContactLink(_.get(account, ['contact', 'id']), _.get(account, ['accountId']))}
                    </HasAccess.Body>
                  </h2>
                  <DisabledContact active={_.get(account, ['contact', 'active'])} />
                  <Table bordered striped>
                    <tbody>
                      <tr>
                        <th>Billing Contact</th>
                        {renderTableData(renderContact(account.contact, 'billing'))}
                      </tr>
                      <tr>
                        <th>Business Contact</th>
                        {renderTableData(renderContact(account.contact, 'business'))}
                      </tr>
                      <tr>
                        <th>Security Contact</th>
                        {renderTableData(renderContact(account.contact, 'security'))}
                      </tr>
                      <tr>
                        <th>Technical Contact</th>
                        {renderTableData(renderContact(account.contact, 'technical'))}
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {this.featuresTable()}
              </Col>
            </Row>
          </div>
        </div>
      </HasAccess>
    );
  }

  private featuresTable(): React.ReactNode {
    const { account } = this.props;
    if (account.lineage === 'WAFFLE_IRON') {
      const disabledFeatures = account.accountType === 'SANDBOX' || account.partition !== 'GLOBAL' ? ['route53'] : [];
      if (account.partition !== 'GLOBAL') {
        disabledFeatures.push('nikePlatformEngine');
        disabledFeatures.push('nikeMultiCloudNetwork');
      }
      return (
        <div>
          <h2>Opt-In Features</h2>
          <FeatureEditor
            partition={account.partition}
            features={account.features}
            onChange={(value: any) => this.updateAccount({ features: value })}
            disabledFeatures={disabledFeatures}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withAccountClient(AccountEditor);
