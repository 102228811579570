import { AccountClient } from './AccountClient';
import { OktaAuth } from '@okta/okta-auth-js';

export class HttpAccountClient implements AccountClient {
  auth: OktaAuth;

  constructor(auth: OktaAuth) {
    this.auth = auth;
  }

  saveAccount = async (accountId: string, mutableData: any): Promise<any> => {
    const accessToken = await this.auth.getAccessToken();
    return fetch('/cloudtools/accounts/v1/' + accountId, {
      method: 'PATCH',
      body: JSON.stringify(mutableData),
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  };

  requestAccountDeletion = async (accountId: string): Promise<any> => {
    const accessToken = await this.auth.getAccessToken();
    return fetch('/cloudtools/accounts/v1/' + accountId, {
      method: 'DELETE',
      headers: { Authorization: 'Bearer ' + accessToken },
    });
  };
}
