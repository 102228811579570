export const WAFFLE_API = 'waffle-api';
export const WAFFLE_GRAPH = 'waffle-graph';
export const CONTACTS_API = 'cloud-account-contacts';
export const LDAP = 'waffle-graph-ldap';
export const CLOUDRED = 'waffle-graph-cloudred';
export const COST_ALLOCATION = 'waffle-cost-allocation';

export interface ServiceInfo {
  id: string;
  label: string;
  slackChannel?: string;
}

export const waffleApi = {
  id: WAFFLE_API,
  label: 'WaffleIron API',
  slackChannel: 'waffle-iron',
};

export const contacts = {
  id: CONTACTS_API,
  label: 'WaffleIron Contacts API',
  slackChannel: 'waffle-iron',
};

export const waffleGraph = {
  id: WAFFLE_GRAPH,
  label: 'WaffleIron GraphQL API',
};

export const cloudred = {
  id: CLOUDRED,
  label: 'CloudRed',
  slackChannel: 'cloudred-discussion',
};

export const ldap = {
  id: LDAP,
  label: 'WaffleIron LDAP integration',
  slackChannel: 'waffle-iron',
};

export const allServices = [
  waffleApi,
  contacts,
  waffleGraph,
  cloudred,
  ldap,
];
