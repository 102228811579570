import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import _ from 'lodash';

import './AccountList.scss';
import {Link} from "react-router-dom";

export interface AccountListProps {
  cardTitle: string;
  accounts: { [key: string]: any }[];
  sortKey?: string;
  columnFields?: string[];
  numPerPage?: number;
  removeLinks?: boolean; // StoryBook fails when using Link tags outside of a Router
}

const titleCaseStr = (text: string) => {
  let result = text.replace(/([A-Z])/g, " $1");
  result = result.replace(/( id)/i, " ID");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const accountIdFormatter = (cell: string, removeLinks: boolean | undefined) => {
  return removeLinks ? cell : <Link to={'/account/' + cell}>{cell}</Link>;
}

const getDefaultColumns = (removeLinks: boolean | undefined) =>
  [
    {
      dataField: 'accountId',
      text: 'Account ID',
      formatter: (cell: string) => accountIdFormatter(cell, removeLinks),
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'contactId',
      text: 'Contact ID',
      sort: true,
    },
  ]

const valueFormatter = (fieldName: string, cell: any, props: AccountListProps) => {
  if (fieldName === "accountId") {
    return accountIdFormatter(cell, props.removeLinks);
  } else if (cell && [].constructor.name === cell.constructor.name) {
    return cell.join(', ');
  } else {
    return cell;
  }
}

export default (props: AccountListProps) => {
  const columns = props.columnFields ? _.map(props.columnFields, key => ({
    dataField: key,
    text: titleCaseStr(key),
    sort: true,
    formatter: (cell: any) => valueFormatter(key, cell, props),
  })) : getDefaultColumns(props.removeLinks);

  const pagination = paginationFactory({
    sizePerPage: props.numPerPage ? props.numPerPage : 5,
    showTotal: true,
    custom: true,
    totalSize: (props.accounts || []).length,
  });

  return (
    <div className="list-card">
      <PaginationProvider pagination={pagination}>
        {({ paginationProps, paginationTableProps }: any) => (
          <div className="list-card-table">
            <div className="list-card-header">
              <div className="list-card-title">{props.cardTitle}</div>
              <div className="list-card-pagination">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
            <BootstrapTable
              bootstrap4={true}
              keyField="accountId"
              data={props.accounts}
              columns={columns}
              defaultSorted={[
                {
                  dataField: props.sortKey ? props.sortKey : "name",
                  order: 'asc',
                },
              ]}
              wrapperClasses="table-next"
              {...paginationTableProps}
            />
          </div>
        )}
      </PaginationProvider>
    </div>
  );
};
