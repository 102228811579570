import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";

interface PilotFeatureButtonProps {
  featureEnabled: boolean;
}

export default class PilotFeatureButton extends React.Component<PilotFeatureButtonProps, {}> {

  render(): React.ReactNode {
    if (this.props.featureEnabled) {
      return (
        <div>
          Enabled
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props: any) => (
              <Tooltip className="feature-tooltip" {...props}>
                This pilot feature has been enabled by the Waffle Iron Team. <br />
                To update this feature for this or another account, please reach out on the #waffle-iron Slack Channel.
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
          </OverlayTrigger>
        </div>

      );
    } else {
      return (
        <div>
          Not Enabled
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props: any) => (
              <Tooltip className="feature-tooltip" {...props}>
                This pilot feature is managed by the Waffle Iron Team, and not yet available for general adoption. <br />
                For more information, please reach out on the #waffle-iron Slack Channel.
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
          </OverlayTrigger>
        </div>
      );
    }
  }
}
