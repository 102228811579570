import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';

export default class ApiError extends Component {
  state = { show: true };

  handleDismiss = () => {
    this.setState({ show: false });
  };

  render() {
    const { error } = this.props;
    if (!error) return null;

    if (this.state.show) {
      const errors = error.errors || [];
      return (
        <Alert variant="danger" onClose={this.handleDismiss} dismissible>
          <Alert.Heading>
            <strong>Error!</strong> {error.message}
          </Alert.Heading>
          <ul>
            {errors.map(error => (
              <li key={error.field}>
                {error.message} {error.field && '(' + error.field + ')'}
              </li>
            ))}
          </ul>
        </Alert>
      );
    } else {
      return null;
    }
  }
}
