import React, { ReactNode } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import MissingAttribute from '../SharedComponents/MissingAttribute';
import filterFactory, { selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FaFileExport } from 'react-icons/fa';
import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiSearchAlt2 } from 'react-icons/bi';
import { MdSearchOff } from 'react-icons/md';
import { BsFillGearFill, BsLink45Deg } from 'react-icons/bs';
import { VscDebugRestartFrame } from 'react-icons/vsc';
const { SearchBar } = Search;

export const defaultSorted = [
  {
    dataField: 'name',
    order: 'asc',
  },
];
export function formatColum(trows: any) {
  const lineage: any = {};
  const types: any = {};
  const partition: any = {};
  const csp: any = {};
  const status: any = {};

  trows.map((curAc: any) => {
    curAc.accountType ? (types[curAc.accountType] = curAc.accountType) : '';
    curAc.cloudServiceProvider ? (csp[curAc.cloudServiceProvider] = curAc.cloudServiceProvider) : '';
    curAc.lineage ? (lineage[curAc.lineage] = curAc.lineage) : '';
    curAc.partition ? (partition[curAc.partition] = curAc.partition) : '';
    curAc.status ? (status[curAc.status] = curAc.status) : '';
    return null;
  });

  return [
    {
      dataField: 'accountId',
      text: 'Account ID',
      formatter: function (cell: string) {
        return (
          <Link to={'/account/' + cell}>
            <BsLink45Deg />
            {cell}
          </Link>
        );
      },
      sort: true,
    },
    {
      dataField: 'accountUri',
      text: 'Account URI',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: missingDataFormatter,
      classes: missingDataClassSelector,
    },
    {
      dataField: 'accountType',
      text: 'Type',
      sort: true,
      disabled: true,
      formatter: missingDataFormatter,
      classes: missingDataClassSelector,
      filter: selectFilter({
        options: types,
        comparator: Comparator.LIKE,
      }),
    },
    {
      dataField: 'lineage',
      text: 'Lineage',
      sort: true,
      hidden: true,
      formatter: missingDataFormatter,
      filter: selectFilter({
        options: lineage,
        comparator: Comparator.LIKE,
      }),
    },
    {
      dataField: 'partition',
      text: 'Partition',
      sort: true,
      filter: selectFilter({
        options: partition,
        comparator: Comparator.LIKE,
      }),
    },
    {
      dataField: 'cloudServiceProvider',
      text: 'CSP',
      formatter: missingDataFormatter,
      filter: selectFilter({
        options: csp,
        comparator: Comparator.LIKE,
      }),
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: missingDataFormatter,
      filter: selectFilter({
        options: status,
        comparator: Comparator.LIKE,
      }),
      sort: true,
    },
  ];
}
export function TSFExportCSV(props: any) {
  const { csvProps, baseProps, columnToggleProps } = props;
  const handleClick = () => {
    const csvToggles = columnToggleProps.toggles;
    baseProps.columns.map((col: any) => {
      return (col.csvExport = csvToggles[col.dataField]);
    });
    csvProps.onExport();
  };
  return (
    <button className="tsf-export" onClick={handleClick}>
      <FaFileExport />
    </button>
  );
}
export function missingDataFormatter(cell: string | undefined): ReactNode {
  return cell || <MissingAttribute />;
}

export function missingDataClassSelector(cell: string | undefined): string {
  if (!cell) {
    return 'warning-table-cell';
  } else {
    return '';
  }
}

interface AccountsTableProps {
  accounts: object[];
}

export default class AccountsTable extends React.Component<AccountsTableProps, any> {
  state = {
    searchTerm: '',
    selectedCategory: '',
    toggleColumn: false,
  };

  updateTypeFilter = (): void => {
    this.setState({
      toggleColumn: !this.state.toggleColumn,
    });
  };
  render(): React.ReactNode {
    const { accounts } = this.props;
    const { toggleColumn } = this.state;
    if (accounts === null || accounts === undefined) {
      return null;
    }

    const colmn = formatColum(accounts);
    const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {columns
          .map((column: any) => ({
            ...column,
            toggle: toggles[column.dataField],
          }))
          .map((column: any) =>
            column.dataField === 'accountId' || column.dataField === 'name' ? (
              ''
            ) : (
              <Form.Check
                inline
                label={column.text}
                name="group1"
                type="checkbox"
                key={column.dataField}
                id={`inline-radio-${column.dataField}`}
                data-toggle="button"
                defaultChecked={column.toggle ? true : false}
                aria-pressed={column.toggle ? 'true' : 'false'}
                className={column.toggle ? 'active' : ''}
                onClick={() => onColumnToggle(column.dataField)}
              />
            )
          )}
      </div>
    );

    const pagination = paginationFactory({
      sizePerPage: 20,
      showTotal: true,
      alwaysShowAllBtns: true,
      custom: true,
      totalSize: (accounts || []).length,
    });
    return (
      <ToolkitProvider
        keyField="accountId"
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        search
        data={accounts}
        columns={colmn}
        columnToggle
      >
        {(props: any) => (
          <PaginationProvider pagination={pagination}>
            {({ paginationProps, paginationTableProps }: any) => (
              <div className="accounts-container">
                <div className="accounts-table">
                  <Row>
                    <Col lg={4} sm={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }}>
                      <BiSearchAlt2 className="searchico" />
                      <SearchBar {...props.searchProps} />
                    </Col>
                    <Col lg={8} className="text-right" sm={{ span: 12, order: 2 }} md={{ span: 8, order: 2 }}>
                      <TSFExportCSV {...props} />
                      <button className="column-toggle" onClick={this.updateTypeFilter}>
                        {toggleColumn ? <VscDebugRestartFrame /> : <BsFillGearFill />}
                      </button>
                    </Col>
                  </Row>

                  <div className={`toggle-bar visible-${toggleColumn}`}>
                    <CustomToggleList {...props.columnToggleProps} />
                  </div>
                  <div className="accounts-pagination">
                    <PaginationListStandalone {...paginationProps} />
                  </div>

                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    bootstrap4={true}
                    keyField="accountUri"
                    filter={filterFactory()}
                    defaultSorted={defaultSorted}
                    {...paginationTableProps}
                    noDataIndication={() => (
                      <h5>
                        <MdSearchOff />
                        No Data found !
                      </h5>
                    )}
                  />
                  <PaginationTotalStandalone {...paginationProps} />
                  <div className="accounts-pagination">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
    );
  }
}