import * as yup from 'yup';

export interface ExistingAccount {
  name: string;
  accountType: string;
}

export const contactSchema = yup.object({
  business: yup.string().required('Business contact is a required field, and must not be VP or above'),
  billing: yup.string().required('Billing contact is a required field, and must not be VP or above'),
  security: yup.string().required('Security contact is a required field, and must not be VP or above'),
  technical: yup.string().required('Technical contact is a required field, and must not be VP or above'),
});

export const featuresSchema = yup.object({
  route53: yup.object({
    nikecloud: yup.bool().required(),
  }),
  serviceCatalog: yup.object({
    wafflebar: yup.bool().required(),
  }),
  cicd: yup.object({
    terraform: yup.bool().required(),
    brewmasters: yup.array().of(yup.string()),
  }),
});

export function doesNotEndWithAccountType(s: string | null): boolean {
  const accountName = s || '';
  return !['sandbox', 'test', 'prod'].some((accountType: string) => accountName.endsWith(accountType));
}

export const accountSchema = yup.object({
  name: yup
    .string()
    .matches(/^[^A-Z]+$/, 'Account names must not contain uppercase characters')
    .matches(/^[^_]+$/, 'Account names must not contain underscores')
    .required('Account name is required')
    .test(
      'ends-with-account-type',
      'Account name must not end with "sandbox", "prod", or "test"',
      doesNotEndWithAccountType
    ),
  // features: featuresSchema,
});

export const createAccountFormSchema = yup.object({
  contact: contactSchema,
  account: accountSchema,
  isTosAccepted: yup.bool(),
  isProd: yup.bool(),
  isTest: yup.bool(),
  isSandbox: yup.bool(),
});
