import React, { Component } from 'react';
import _ from 'lodash';
import Alert from 'react-bootstrap/Alert';

export default class QueryError extends Component {
  render() {
    const { errors, errorFilter } = this.props;
    const graphQLErrors = _.get(errors, 'graphQLErrors', []);
    const filteredErrors = errorFilter ? graphQLErrors.filter(errorFilter) : graphQLErrors;

    if (!filteredErrors.length) return null;

    return (
      <Alert variant="danger">
        <strong>Error!</strong> Unable to execute query. Data is incomplete.
        <ul>
          {filteredErrors.map(error => {
            if (error.path) {
              return (
                <li key={error.path.join('.')}>
                  {error.path.join('.')}: {error.message}
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </Alert>
    );
  }
}
