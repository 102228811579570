import React from 'react';
import Button from 'react-bootstrap/Button';

interface EnableFeatureButtonProps {
  onChange: (value: boolean | null) => void;
  disabledBtn?: boolean;
  toggleTerraform?: boolean;
}

interface EnableFeatureButtonState {
  enabled: boolean;
}

export default class EnableFeatureButton extends React.Component<EnableFeatureButtonProps, EnableFeatureButtonState> {
  state = {
    enabled: false,
  };

  toggle = (): void => {
    const enabled = !this.state.enabled;
    this.setState({
      enabled,
    });
    if (enabled) {
      this.props.onChange(true);
    } else {
      this.props.onChange(null); //if the feature is not enabled, we want it to be null for a no-op at the API
    }
  };

  componentDidUpdate = (prevProps: EnableFeatureButtonProps): void => {
    if (prevProps.toggleTerraform !== this.props.toggleTerraform)
      if (this.props.toggleTerraform === false) this.setState({ enabled: false });
  };

  render(): React.ReactNode {
    if (this.state.enabled) {
      return (
        <Button variant="danger" onClick={this.toggle} disabled={this.props.disabledBtn}>
          Disable
        </Button>
      );
    } else {
      return (
        <Button variant="success" onClick={this.toggle} disabled={this.props.disabledBtn}>
          Enable
        </Button>
      );
    }
  }
}
