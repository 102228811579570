import * as _ from 'lodash';
import * as servicesUnavailable from './ServicesUnavailable';
import * as servicesModule from './services';

export interface GraphResponse {
  data: any,
}

export interface AvailabilityData {
  unavailable: string[];
  ok: boolean;
}

export interface GraphDataServiceChecker {
  serviceName: string;
  dataPath: string[];
  validateData?: (x: any) => boolean;
}

export const defaultValidateData = _.identity.bind(_);

export const validate = (checker: GraphDataServiceChecker, graphResponse: GraphResponse) => {
  const validateData = checker.validateData || defaultValidateData;
  const value = _.get(graphResponse.data, checker.dataPath);
  return Boolean(validateData(value));
};

export const checkAvailability = (checkers: GraphDataServiceChecker[], graphResponse: GraphResponse): AvailabilityData => {
  const unavailable = checkers.reduce((acc, c) => {
    return !validate(c, graphResponse) ? acc.concat([c.serviceName]) : acc;
  }, [] as string[]);

  return {
    unavailable,
    ok: unavailable.length === 0,
  };
};

export const services = servicesModule.allServices;
export const ServicesUnavailable = servicesUnavailable.ServicesUnavailable;
export const ServicesUnavailableModal = servicesUnavailable.ServicesUnavailableModal;

export const servicesById: { [k: string]: servicesModule.ServiceInfo } = services.reduce((m, s) => Object.assign(m, {[s.id]: s}), {});

export const serviceById = (id: string) => {
  return servicesById[id];
}
