import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';

const loggedInName = gql`
  {
    currentUser {
      name
    }
  }
`;

const withLoggedInName = withGraphQuery(loggedInName, {
  options: () => ({
    errorPolicy: 'all',
  }),
});

const CurrentUser = withLoggedInName(({ data }) => {
  if (data && data.currentUser) {
    return data.currentUser.name;
  } else {
    return null;
  }
});

export default CurrentUser;
