import React from 'react';
import { Table } from 'react-bootstrap';
import { listFeatures, setFeature } from './SharedComponents/FeatureFlag';
import { TerminationProtectionToggle as Toggle } from './AccountDetail/TerminationProtectionToggle';

export default class FeatureFlag extends React.Component {
  state = {
    features: listFeatures(),
  };

  async updateFeature(feature: string, value: boolean): Promise<void> {
    setFeature(feature, value);
    await this.setState({
      features: listFeatures(),
    });
  }

  render(): React.ReactNode {
    return (
      <div className="content-container">
        <h1 className="mt-2">Feature Flags</h1>
        <Table bordered striped>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {this.state.features.map(feature => {
              return (
                <tr key={feature.name}>
                  <td>{feature.name}</td>
                  <td>
                    <Toggle
                      value={feature.value}
                      id={'feature-toggle-' + feature.name}
                      onValueChange={(value: boolean) => this.updateFeature(feature.name, value)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
