import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { LoggerFactory } from '../../util/LoggerFactory';
import '../../styles/shared.scss';

const log = LoggerFactory.getLogger('Deletion Requests');

interface DeletionRequestJob {
  jobId: string;
}
interface DeletionRequestAccount {
  accountId: string;
  name: string;
  accountType: string;
}
interface DeletionRequest {
  id: string;
  account: DeletionRequestAccount;
  job: DeletionRequestJob;
}

interface DeletionRequestsTableProps {
  requests: DeletionRequest[];
}

const tableColumns = [
  {
    dataField: 'id',
    text: 'Approval Id',
  },
  {
    dataField: 'job.jobId',
    text: 'Job Id',
  },
  {
    dataField: 'account.accountId',
    text: 'Account Id',
    formatter: (accountId: string) => {
      return accountId ? <Link to={`/account/${accountId}`}>{accountId}</Link> : '';
    },
  },
  {
    dataField: 'account.name',
    text: 'Account Name',
  },
  {
    dataField: 'account.accountType',
    text: 'Account Type',
  },
];

class DeletionRequestsTable extends React.Component<DeletionRequestsTableProps, {}> {
  render(): React.ReactNode {
    log.debug('props:', this.props);
    const { requests } = this.props;

    return (
      <div className="content-container">
        <h1 className="mt-4">Deletion Requests</h1>
        <BootstrapTable columns={tableColumns} data={requests} keyField="id" />
      </div>
    );
  }
}

export const deletionRequestsQuery = gql`
  query DeletionRequests {
    manualTasks {
      objects {
        id
        account {
          accountId
          name
          accountType
        }
        job {
          jobId
        }
      }
    }
  }
`;

export const DeletionRequests = withGraphQuery(deletionRequestsQuery, {
  options: () => {
    return {
      errorPolicy: 'all',
    };
  },
})((graphqlResponse: any) => {
  const { data } = graphqlResponse;
  return <DeletionRequestsTable requests={data.manualTasks.objects} />;
});

export default DeletionRequests;
