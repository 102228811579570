import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Login from './Login';
import Logout from './Logout';
import CurrentUser from './CurrentUser';
import './Header.scss';
import FeatureFlag from '../SharedComponents/FeatureFlag';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';

export class Header extends React.Component<IOktaContext> {
  state = { authenticated: null };

  checkAuthentication = async (): Promise<any> => {
    const authenticated = await this.props.oktaAuth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  };

  async componentDidUpdate(): Promise<void> {
    await this.checkAuthentication();
  }
  async componentDidMount(): Promise<void> {
    await this.checkAuthentication();
  }

  showAuthenticatedHeader(): any {
    const { authenticated } = this.state;
    if (authenticated === null) {
      return null;
    } else {
      return (
        <Navbar.Collapse className="justify-content-end headline-4">
          <Navbar.Text className="mr-sm-2 waffle-iron-link">{authenticated && <CurrentUser />}</Navbar.Text>
          <Navbar.Text>{authenticated ? <Logout /> : <Login />}</Navbar.Text>
        </Navbar.Collapse>
      );
    }
  }

  render(): React.ReactNode {
    return (
      <div id="header-wrapper">
        <Container fluid id="header">
          <Navbar bg="header-navbar" variant="dark" expand="lg">
            <Navbar.Brand as={Link} to="/" className="display-3 waffle-iron-link">
              Waffle Iron
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse id="header-nav">
              <Nav>
                <Nav.Link as={Link} to="/accounts/create" className="display-3">
                  Create Account
                </Nav.Link>
                <Nav.Link as={Link} to="/my-accounts" className="display-3">
                  My Accounts
                </Nav.Link>
                <Nav.Link as={Link} to="/my-requests" className="display-3">
                  My Requests
                </Nav.Link>
                <Nav.Link
                  href="https://confluence.nike.com/x/Kun2Dg"
                  className="display-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Waffle Iron FAQ
                </Nav.Link>
                <FeatureFlag feature="features">
                  <Nav.Link as={Link} to="/feature-flags" className="display-3">
                    Feature Flags
                  </Nav.Link>
                </FeatureFlag>
              </Nav>
            </Navbar.Collapse>
            {this.showAuthenticatedHeader()}
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default withOktaAuth(Header);
