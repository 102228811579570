import React from 'react';
import { Form } from 'react-bootstrap';

export interface TerminationProtectionToggle {
  value: boolean | null;
  onValueChange: (newValue: boolean) => void;
}

export class TerminationProtectionToggle extends React.Component<any, any> {
  render(): React.ReactNode {
    const { id, value, onValueChange } = this.props;
    const label = value ? 'enabled' : 'disabled';
    return (
      <Form.Group>
        <Form.Check
          id={id ? id : 'account-editor-termination-protection'}
          type="switch"
          value={value}
          checked={value}
          label={label}
          onChange={(e: any) => {
            if (onValueChange) {
              onValueChange(!value);
            }
          }}
        ></Form.Check>
      </Form.Group>
    );
  }
}
