import React from 'react';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { LoggerFactory } from '../../util/LoggerFactory';
import CreateAccountStatusTablePure from './CreateAccountStatusTable';

const log = LoggerFactory.getLogger('Create Account Status');

export const createAccountStatusesQuery = gql`
  query CreateAccountStatuses($jobIds: [ID]!) {
    createAccountStatuses(jobIds: $jobIds) {
      jobId
      accountId
      contactId
      tagsetId
      status
      createAccountJobId

      errors {
        component
        message
      }
    }
  }
`;

interface CreateAccountStatusPageProps {
  jobIds: string[];
}

function hasCompleted(jobStatus: any): boolean {
  return jobStatus.status === 'COMPLETED' || jobStatus.status === 'FAILED';
}

const CreateAccountStatusTable = withGraphQuery(createAccountStatusesQuery, {
  options: ({ jobIds }: CreateAccountStatusPageProps) => {
    log.debug('jobIds:', jobIds);
    return {
      errorPolicy: 'all',
      variables: { jobIds },
    };
  },
})((graphqlResponse: any) => {
  const { data } = graphqlResponse;
  const { startPolling, stopPolling } = data;

  if (!(data.createAccountStatuses || []).every(hasCompleted)) {
    startPolling(2000);
  } else {
    stopPolling();
  }

  return <CreateAccountStatusTablePure jobStatuses={data.createAccountStatuses} />;
});

export class CreateAccountStatusPage extends React.Component<CreateAccountStatusPageProps, {}> {
  render(): React.ReactNode {
    const { jobIds } = this.props;
    return <CreateAccountStatusTable jobIds={jobIds} />;
  }
}

export default CreateAccountStatusPage;
