import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

interface DeletionResource {
  name: string;
  region: string;
  type: string;
}

interface FailedToDeleteResource extends DeletionResource {
  reason: string;
}

interface DeletionDetails {
  //status: string;
  accountType: string;
  failedToDeleteResources: FailedToDeleteResource[];
  deletedResources: DeletionResource[];
  ignoredResources: DeletionResource[];
  resources: DeletionResource[];
}

const resourceColumns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'region',
    text: 'Region',
    sort: true,
  },
  {
    dataField: 'type',
    text: 'Type',
    sort: true,
  },
];

const failedToDeleteColumns = resourceColumns.concat([
  {
    dataField: 'reason',
    text: 'reason',
    sort: true,
  },
]);

/**
 * react expects ui elements to have unique "key". These items due not.
 */
export function withKey(list: any[]): any[] {
  let i = 0;
  if (list) {
    return list.map(item => {
      item.key = item.name + '_' + item.region + '_' + item.type + '_' + i++;
      return item;
    });
  } else {
    return [];
  }
}

export default class DeletionDetailsTable extends Component<DeletionDetails> {
  renderSandbox(): React.ReactNode {
    return (
      <Tabs
        id="deletion-details"
        defaultActiveKey={this.props.failedToDeleteResources.length > 0 ? 'failed' : 'deleted'}
      >
        <Tab eventKey="failed" title="Failed To Delete Resources">
          <BootstrapTable
            striped
            wrapperClasses="table-responsive"
            bootstrap4={true}
            keyField="key"
            data={withKey(this.props.failedToDeleteResources)}
            columns={failedToDeleteColumns}
          />
        </Tab>
        <Tab eventKey="deleted" title="Deleted Resources">
          <BootstrapTable
            striped
            wrapperClasses="table-responsive"
            bootstrap4={true}
            keyField="key"
            data={withKey(this.props.deletedResources)}
            columns={resourceColumns}
          />
        </Tab>
        <Tab eventKey="ignored" title="Ignored Resources">
          <BootstrapTable
            striped
            wrapperClasses="table-responsive"
            bootstrap4={true}
            keyField="key"
            data={withKey(this.props.ignoredResources)}
            columns={resourceColumns}
          />
        </Tab>
      </Tabs>
    );
  }
  renderNonSandbox(): React.ReactNode {
    return (
      <Tabs id="deletion-details">
        <Tab eventKey="resources" title="Resources To Delete">
          <BootstrapTable
            striped
            wrapperClasses="table-responsive"
            bootstrap4={true}
            keyField="key"
            data={withKey(this.props.resources)}
            columns={resourceColumns}
          />
        </Tab>
        <Tab eventKey="ignored" title="Ignored Resources">
          <BootstrapTable
            striped
            wrapperClasses="table-responsive"
            bootstrap4={true}
            keyField="key"
            data={withKey(this.props.ignoredResources)}
            columns={resourceColumns}
          />
        </Tab>
      </Tabs>
    );
  }
  render(): React.ReactNode {
    if (this.props.accountType === 'SANDBOX') {
      return this.renderSandbox();
    } else {
      return this.renderNonSandbox();
    }
  }
}
