import React, { ReactNode, ErrorInfo } from 'react';
import { SecureRoute, withOktaAuth } from '@okta/okta-react';
import Header from './Header/Header';
import Accounts from './AccountList/Accounts';
import AccountDetail from './AccountDetail/AccountDetail';
import ContactDisplay from './Contacts/ContactDisplay';
import CreateAccount from './CreateAccount/CreateAccount';
import CreateAccountStatus from './CreateAccountStatus/CreateAccountStatus';
import DeletionRequests from './DeletionRequests/DeletionRequests';
import MyCreateAccountStatuses from './CreateAccountStatus/MyCreateAccountStatuses';
import FeatureFlags from './FeatureFlags';
import { RouteComponentProps, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import 'typeface-assistant/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Main.scss';
import MyAccounts from "./MyAccounts/MyAccounts";
import { slackLink } from './SharedComponents/Links';

interface AccountIdMatch {
  accountId: string;
}

interface ContactIdAndAccountIdMatch {
  contactId: string;
  accountId: string;
}

function NotFound(): React.FunctionComponentElement<ReactNode> {
  return <h1>Not Found</h1>;
}

export interface ContentErrorBoundaryProps {
  children: any;
}

export interface ContentErrorBoundaryState {
  error: Error | null;
}

export class BaseContentErrorBoundary extends React.Component<RouteComponentProps<ContentErrorBoundaryProps>, ContentErrorBoundaryState> {

  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /* eslint-disable no-console */
    console.log('error caught by ContentErrorBoundary', error, errorInfo);
    /* eslint-enable no-console */
  }

  componentDidUpdate(prevProps: RouteComponentProps<ContentErrorBoundaryProps>) {
    // Need to clear the error if the path changes so routing still
    // works after an error occurs.
    // https://stackoverflow.com/questions/60198108/react-router-stops-working-when-error-boundary-catches-an-error-in-route
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ error: null });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <header><h1 className="h3">Something went wrong!</h1></header>
          <p>
            Please reach out in the {slackLink('waffle-iron')} slack channel for further assitance.
          </p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }

}

export const ContentErrorBoundary = withRouter(BaseContentErrorBoundary);

export default withOktaAuth(
  class Main extends React.Component<any> {
    render(): React.ReactNode {
      return (
        <div id="main">
          <Header />
          <ContentErrorBoundary>
            <div>
              <div>
                <Switch>
                  <SecureRoute path="/" exact component={Accounts} />
                  <SecureRoute path="/accounts/create" exact component={CreateAccount} />
                  <SecureRoute path="/my-accounts" exact component={MyAccounts} />
                  <SecureRoute
                    path="/accounts/create-status/:jobsCsv"
                    exact
                    render={(props: any) => {
                      const jobIds = decodeURIComponent(props.match.params.jobsCsv).split(',');
                      return <CreateAccountStatus jobIds={jobIds} />;
                    }}
                  />
                  <SecureRoute
                    path="/my-requests"
                    exact
                    render={() => {
                      return <MyCreateAccountStatuses />;
                    }}
                  />
                  <SecureRoute
                    path="/account/:accountId"
                    exact
                    render={(props: RouteComponentProps<AccountIdMatch>) => (
                      <AccountDetail accountId={props.match.params.accountId} />
                    )}
                  />
                  <SecureRoute path="/deletion-requests" exact render={() => <DeletionRequests />} />
                  <SecureRoute
                    path="/contact/:contactId/:accountId"
                    exact
                    render={(props: RouteComponentProps<ContactIdAndAccountIdMatch>) => (
                      <ContactDisplay contactId={props.match.params.contactId} accountId={props.match.params.accountId} />
                    )}
                  />
                  <SecureRoute path="/feature-flags" exact render={() => <FeatureFlags />} />
                  <SecureRoute component={NotFound} />
                </Switch>
              </div>
            </div>
          </ContentErrorBoundary>
        </div>
      );
    }
  }
);
