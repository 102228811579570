import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { createBrowserHistory } from 'history';
import 'typeface-assistant/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import AnalyticsTracker from './AnalyticsTracker';
import { ApolloClientConfig } from '../config/ApolloClientConfig';
import ContactClientProvider from './Clients/ContactClient';
import { HttpContactClient } from './Clients/HttpContactClient';
import AccountClientProvider from './Clients/AccountClient';
import { HttpAccountClient } from './Clients/HttpAccountClient';
import { stores } from '../stores';
import Main from './Main';
// import { truncate } from 'lodash';

const history = createBrowserHistory();

const authorizations = new OktaAuth({
  issuer: process.env.REACT_APP_AUTH_ENDPOINT,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: [
    'openid',
    'email',
    'profile',
    // 'cloudtools.accounts.read',
    // 'cloudtools.accounts.create',
    // 'cloudtools.contacts.read',
    // 'cloudtools.accounts.update',
    // 'cloudtools.contacts.update',
    // 'cloudtools.contacts.create',
    // 'cloudtools.ssot.tagsets.read',
    // 'cloudtools.ssot.tagsets.create',
    // 'cloudtools.waffle.manualtasks.read',
    // 'cloudtools.accounts.delete',
    // 'cloudtools.costallocation.read',
  ],
  storageManager: {
    token: {
      storageType: 'sessionStorage',
    },
  },
  pkce: true,
  tokenManager: {
    autoRenew: true,
  },
});

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string): Promise<void> => {
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

const client = new ApolloClient({
  link: ApolloClientConfig(authorizations),
  cache: new InMemoryCache(
    { addTypename: false, }
  ),
});

const contactClient = new HttpContactClient(authorizations);
const accountClient = new HttpAccountClient(authorizations);
export default class App extends Component<any> {
  render(): React.ReactNode {
    return (
      <ApolloProvider client={client}>
        <Provider {...stores}>
          <ContactClientProvider contactClient={contactClient}>
            <AccountClientProvider accountClient={accountClient}>
              <Router history={history}>
                <Security oktaAuth={authorizations} restoreOriginalUri={restoreOriginalUri}>
                  <Route path="/implicit/callback" component={LoginCallback} />
                  <AnalyticsTracker />
                  <Main history={history} />
                </Security>
              </Router>
            </AccountClientProvider>
          </ContactClientProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}
