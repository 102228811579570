import gql from 'graphql-tag';

export const createAccountMutation = gql`
  mutation CreateAccount(
    $account: CreateAccountRequestInput!
    $contact: ContactInput!
    $partition: Partition!
    $tags: [TagInput!]!
  ) {
    createAccountRequest(
      account: $account
      contact: $contact
      partition: $partition
      tags: $tags
    ) {
      jobId
    }
  }
`;
