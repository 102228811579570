import React, { ReactElement, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { ServiceInfo } from './services';
import { slackLink } from '../SharedComponents/Links';
import { Banner } from '../Banner';

export interface ServicesUnavailableProps {
  services: ServiceInfo[];
}

export const ServicesUnavailable: React.FC<ServicesUnavailableProps> = ({ services }): ReactElement => {
  if (!services || !services.length) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <header><h1 className="h3">One or more downstream services is unavailable at this time.</h1></header>
        <p>
          Please reach out in the {slackLink('waffle-iron')} slack channel for further assitance.
        </p>
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="h3">The following downstream services may be unavailable at this time:</h1>
        <ul>
          {services.map(s => {
            const link = !s.slackChannel ? null : (
              <span> ({slackLink(s.slackChannel)})</span>
            );
            return (
              <li key={s.id}>{s.label}{link}</li>
            );
          })}
        </ul>
        <p>
          Please reach out in the {slackLink('waffle-iron')} slack channel for further assistance.
        </p>
      </div>
    );
  }
}

export const ServicesUnavailableModal: React.FC<ServicesUnavailableProps> = ({ services, children }): ReactElement => {
  const [showModal, setShow] = useState(true);
  const closeModal = () => setShow(false);
  return (
    <div>
      {children}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Body>
          <ServicesUnavailable services={services} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const ServicesUnavailableBanner: React.FC<ServicesUnavailableProps> = ({ services, children }): ReactElement => {
  return (
    <Banner>
      <h1 className="h3 mt-2">
        One or more downstream services may be unavailable at this time.
      </h1>
      <p className="h5">
        Please reach out in the {slackLink('waffle-iron')} slack channel for further assistance.
      </p>
    </Banner>
  );
}
