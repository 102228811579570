import React from 'react';
import { Subtract } from 'utility-types';
import { LoggerFactory } from '../../util/LoggerFactory';

const log = LoggerFactory.getLogger('Contact Client');

export interface ContactClient {
  saveContact(contactId: string, contact: any): Promise<any>;
}

const ContactClientContext = React.createContext<ContactClient | null>(null);

export interface WithContactClientProps {
  contactClient: ContactClient;
}

export default class ContactClientProvider extends React.Component<WithContactClientProps> {
  render(): React.ReactNode {
    return (
      <ContactClientContext.Provider value={this.props.contactClient}>
        {this.props.children}
      </ContactClientContext.Provider>
    );
  }
}

export const withContactClient = <P extends WithContactClientProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Subtract<P, WithContactClientProps>> => {
  class WithContactClient extends React.Component<Subtract<P, WithContactClientProps>> {
    static contextType = ContactClientContext;

    render(): React.ReactNode {
      const contactClient: ContactClient = this.context;
      log.debug('contactClient', contactClient);
      if (contactClient === undefined) {
        throw new Error('no contactClient defined');
      }
      const props = this.props as P;
      return <Component {...props} contactClient={contactClient} />;
    }
  }
  return WithContactClient;
};
