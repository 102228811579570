import React from 'react';

const HasAccessContext = React.createContext<boolean>(false);

export interface HasAccessProps {
  hasAccess: boolean;
}

class NoAccess extends React.Component {
  static contextType = HasAccessContext;

  render(): React.ReactNode {
    if (this.context) {
      return null;
    } else {
      return <span>{this.props.children}</span>;
    }
  }
}

class Body extends React.Component {
  static contextType = HasAccessContext;

  render(): React.ReactNode {
    if (this.context) {
      return <span>{this.props.children}</span>;
    } else {
      return null;
    }
  }
}

export default class HasAccess extends React.Component<HasAccessProps> {
  static NoAccess = NoAccess;
  static Body = Body;

  render(): React.ReactNode {
    return <HasAccessContext.Provider value={this.props.hasAccess}>{this.props.children}</HasAccessContext.Provider>;
  }
}
