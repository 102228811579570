import React from 'react';
import Table from 'react-bootstrap/Table';
import JobStatus from './JobStatus';
import _ from 'lodash';
import { AccountTitle } from './AccountTitle';
import DeleteDetails from './DeletionDetail/DeletionDetails';
import moment from 'moment';
import Date from './Date';

export function sortJobs(jobs: any): any {
  return _.sortBy(jobs, [
    (job: any) => {
      if (job.startDate) {
        return -moment(job.startDate, moment.ISO_8601, true).valueOf();
      } else {
        return -moment().valueOf();
      }
    },
  ]);
}

interface Account {
  jobs: any;
  name: string;
  accountType: string;
}

interface AccountJobsProps {
  account: Account;
}

export default class AccountJobs extends React.Component<AccountJobsProps> {
  showDetails(job: any): React.ReactNode | null {
    if (job.type === 'DELETE') {
      return <DeleteDetails jobId={job.jobId} />;
    } else {
      return null;
    }
  }
  render(): React.ReactNode {
    const { account } = this.props;
    const { jobs } = account;

    if (jobs === null) {
      return null;
    } else if (jobs.length === 0) {
      return (
        <div>
          <AccountTitle account={account} />
          No Event History
        </div>
      );
    } else {
      return (
        <div>
          <AccountTitle account={account} />
          <Table bordered striped>
            <thead>
              <tr>
                <th>Type</th>
                <th>Start</th>
                <th>End</th>
                <th>Status</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              {sortJobs(jobs).map((job: any) => (
                <tr key={job.jobId}>
                  <td>
                    {job.type}
                    {this.showDetails(job)}
                  </td>
                  <td>
                    <Date date={job.startDate} />
                  </td>
                  <td>
                    <Date date={job.endDate} />
                  </td>
                  <td>
                    <JobStatus {...job} />
                  </td>
                  <td>{job.jobId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
  }
}
