import React from 'react';
import Table from 'react-bootstrap/Table';
import _ from 'lodash';
import omitByDeep from '../../util/omitByDeep';
import ContactInput from './ContactInput';
import { renderTableData } from '../SharedComponents/MissingAttribute';
import { WithContactClientProps, withContactClient } from '../Clients/ContactClient';
import ApiError from '../SharedComponents/ApiError';
import HasAccess from '../SharedComponents/HasAccess';
import { LoggerFactory } from '../../util/LoggerFactory';
import DisabledContact from './DisabledContact';
import { Alert, Button } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';

const log = LoggerFactory.getLogger('Contact Editor');

export function renderContact(contact: object, type: string, field?: string): string {
  const path = field ? `${type}.primary.${field}` : `${type}.primary.name`;
  return _.get(contact, path, '');
}

interface ContactDisplayProps {
  onChange: (value: string) => void;
  contactType: string;
  contact: any;
}

export class ContactDisplay extends React.Component<ContactDisplayProps> {
  render(): React.ReactNode {
    const { contact, contactType, onChange } = this.props;
    return (
      <Table bordered striped>
        <tbody>
          <tr>
            <td>User Name:</td>
            <td>
              <ContactInput value={renderContact(contact, contactType, 'username')} onChange={onChange} />
            </td>
          </tr>
          <tr>
            <td>Email:</td>
            {renderTableData(renderContact(contact, contactType, 'email'))}
          </tr>
          <tr>
            <td>Name:</td>
            {renderTableData(renderContact(contact, contactType, 'name'))}
          </tr>
        </tbody>
      </Table>
    );
  }
}

interface ContactEditorProps extends WithContactClientProps {
  contact: any;
  refresh: () => void;
}

interface ContactEditorState {
  preventNav: boolean;
  disButton: boolean;
  mutateContact: object;
  error?: object;
  updatedContact: object;
  isSaving: boolean;
}

export class ContactEditor extends React.Component<ContactEditorProps, ContactEditorState> {
  state = {
    preventNav: false,
    disButton: true,
    mutateContact: this.props.contact,
    error: undefined,
    updatedContact: {},
    isSaving: false,
  };

  updateContact(map: any) {
    const upstring: any = map;
    const userUpdated: any = this.state.updatedContact;
    const index: string = Object.keys(upstring)[0];
    upstring[index].primary.username === ''
      ? delete userUpdated[index]
      : (userUpdated[index] = { primary: upstring[index].primary.username });
    const mergedContact: any = _.mergeWith(this.state.mutateContact, omitByDeep(upstring, _.isEmpty));
    upstring[Object.keys(upstring)[0]].primary === '' ? delete mergedContact[Object.keys(upstring)[0]] : '';
    this.setState({
      mutateContact: mergedContact,
      disButton: _.isEmpty(userUpdated) ? true : false,
      preventNav: true,
      updatedContact: userUpdated,
    });
  }

  saveContact = async (): Promise<void> => {
    const { refresh, contact, contactClient } = this.props;
    log.debug('saving contact');
    await this.setState({
      disButton: true,
      error: undefined,
      isSaving: true,
    });
    try {
      const response = await contactClient.saveContact(contact.id, this.state.updatedContact);
      log.debug('save response', response);
      if (response.status >= 400) {
        log.debug('unable to save response');
        const body = await response.json();
        return this.setState({ error: body, disButton: false });
      }
      this.setState({ disButton: true });
      refresh();
    } finally {
      this.setState({ isSaving: false, disButton: true, preventNav: false });
    }
  };

  render(): React.ReactNode {
    const { contact } = this.props;
    const { isSaving } = this.state;

    return (
      <HasAccess hasAccess={_.get(contact, 'permissions.hasAccess', false)}>
        <div>
          <ApiError error={this.state.error} />
          <Alert variant="info">Individuals with title VP should not be added as Contacts</Alert>
          <Alert variant="info">
            Only the technical contact or account owner can save changes here (save buttons will not appear for others)
          </Alert>
          <HasAccess.Body>
            <Prompt
              when={this.state.preventNav}
              message={(location) => `Are you sure you want leave this page without saving?`}
            />
            <Button
              className="contact-save-button"
              variant="info"
              onClick={this.saveContact}
              disabled={this.state.disButton}
            >
              {isSaving ? 'Saving...' : 'Save contact'}
            </Button>
          </HasAccess.Body>
          <Table bordered striped>
            <tbody>
              <tr>
                <th>ID:</th>
                <th>
                  {contact.id} <DisabledContact active={contact.active} />
                </th>
              </tr>
              <tr>
                <th>Billing Contact</th>
                <td>
                  <ContactDisplay
                    contact={this.state.mutateContact}
                    contactType="billing"
                    onChange={(value: any) => this.updateContact({ billing: { primary: value } })}
                  />
                </td>
              </tr>
              <tr>
                <th>Business Contact</th>
                <td>
                  <ContactDisplay
                    contact={this.state.mutateContact}
                    contactType="business"
                    onChange={(value: any) => this.updateContact({ business: { primary: value } })}
                  />
                </td>
              </tr>
              <tr>
                <th>Security Contact</th>
                <td>
                  <ContactDisplay
                    contact={this.state.mutateContact}
                    contactType="security"
                    onChange={(value: any) => this.updateContact({ security: { primary: value } })}
                  />
                </td>
              </tr>
              <tr>
                <th>Technical Contact</th>
                <td>
                  <ContactDisplay
                    contact={this.state.mutateContact}
                    contactType="technical"
                    onChange={(value: any) => this.updateContact({ technical: { primary: value } })}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </HasAccess>
    );
  }
}

export default withContactClient(ContactEditor);
