import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import DeletionDetailsLoader from './DeletionDetailLoader';
import HistoryModal from '../../SharedComponents/HistoryModal';
import './DeletionDetail.scss'

export interface DeletionDetailsModalProps {
  jobId: string;
}

export class DeletionDetailsModal extends Component<DeletionDetailsModalProps> {
  render(): React.ReactNode {
    return (
      <HistoryModal
        dialogClassName="deletion-details-modal"
        queryParamName="deletionDetailsJobId"
        queryValue={this.props.jobId}
        title="Deletion Details"
        render={open => (
          <Button variant="outline-secondary" onClick={open}>
            Details
          </Button>
        )}
      >
        <DeletionDetailsLoader jobId={this.props.jobId} />
      </HistoryModal>
    );
  }
}

export default DeletionDetailsModal;
