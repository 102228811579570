import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FormikValues } from 'formik';

export interface PartitionSelectProps {
  hasChinaCreatePermission: boolean;
  formik: FormikValues;
}

export class PartitionSelect extends React.Component<PartitionSelectProps> {
  render(): React.ReactNode {
    const { formik, hasChinaCreatePermission } = this.props;
    const { setFieldTouched, setFieldValue, touched, values } = formik;
    return (
      <div>
        <Form.Group>
          <Form.Control
            as="select"
            name={'account.partition'}
            isInvalid={touched.isPartitionSelected && !values.isPartitionSelected}
            onBlur={() => setFieldTouched('isPartitionSelected', true)}
            onChange={(evt: any) => {
              const val = evt.target.value;
              setFieldTouched('isPartitionSelected', true);
              if (val === 'nike_sport') {
                setFieldValue('partition', 'NIKE_SPORT', false);
                setFieldValue('isChina', true, false);
                setFieldValue('isPartitionSelected', true, false);
                setFieldValue('account.features.route53.nikecloud', false, false);
                setFieldValue('account.features.serviceCatalog.wafflebar', false, false);
                setFieldValue('account.features.cicd.terraform', false, false);
                setFieldValue('account.features.cicd.brewmasters', [], false);
                if (values.isAccountTypeSelected && values.isSandbox) {
                  setFieldValue('isAccountTypeSelected', false, false);
                }
              } else if (val === 'nike_business') {
                setFieldValue('partition', 'NIKE_BUSINESS', false);
                setFieldValue('isChina', false, false);
                setFieldValue('isPartitionSelected', true, false);
              } else {
                setFieldValue('partition', 'NIKE_SPORT', false);
                setFieldValue('isPartitionSelected', false, false);
                setFieldValue('isChina', false, false);
              }
            }}
          >
            <option id={'account.partition.none'} value="default">
              Please select a Partition
            </option>
            <option id={'account.partition.china'} value="nike_sport">
              NIKE SPORT
            </option>
            <option id={'account.partition.global'} value="nike_business">
              NIKE BUSINESS
            </option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">You must choose a partition</Form.Control.Feedback>
        </Form.Group>
        <div id={'account.china.create.help'} style={{ display: hasChinaCreatePermission ? 'none' : 'block' }}>
          <Alert variant="secondary">
            <strong>
              Click{' '}
              <a href="https://confluence.nike.com/x/C2PsFg" target={'_blank'} rel={'noreferrer'}>
                here
              </a>{' '}
              for instructions to request an AWS-CHINA account
            </strong>
          </Alert>
        </div>
      </div>
    );
  }
}
