import React from 'react';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import ContactEditor from './ContactEditor';
import { filterNonNullable } from '../../util/filterQueryErrors';
import '../../styles/shared.scss';

const contactQuery = gql`
  query GetContact($contactId: ID) {
    contact(id: $contactId) {
      id
      active
      billing {
        primary {
          email
          name
          username
        }
      }
      business {
        primary {
          email
          name
          username
        }
      }
      security {
        primary {
          email
          name
          username
        }
      }
      technical {
        primary {
          email
          name
          username
        }
      }
      permissions {
        hasAccess
      }
    }
  }
`;

interface GetContactQueryInput {
  contactId: string;
}

const withContact = withGraphQuery(
  contactQuery,
  {
    options: ({ contactId }: GetContactQueryInput) => ({
      errorPolicy: 'all',
      variables: { contactId },
    }),
  },
  filterNonNullable
);

interface ContactViewProps {
  contact: any;
  refresh: () => void;
}

class ContactView extends React.Component<ContactViewProps, {}> {
  render(): React.ReactNode {
    const { contact, refresh } = this.props;

    return (
      <div className="content-container">
        <h1 className="mt-2">Contact Details</h1>
        <ContactEditor contact={contact} refresh={refresh} />
      </div>
    );
  }
}

interface WithContactOptions {
  contact: any;
  refetch: () => void;
}

interface WithContactInput {
  data: WithContactOptions;
}

export default withContact(({ data: { contact, refetch } }: WithContactInput) => {
  if (contact) {
    return <ContactView contact={contact} refresh={refetch} />;
  } else {
    return <h1>Not Found</h1>;
  }
});
