import React, { Component } from 'react';
import { initialize, set, pageview } from 'react-ga';
import { Route } from 'react-router-dom';

class Analytics extends Component {
  constructor(props) {
    super(props);
    initialize(process.env.REACT_APP_GA_TRACKING_ID);
    this.sendPageChange = (pathname, search = '') => {
      const page = pathname + search;
      set({ page });
      pageview(page);
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.sendPageChange(location.pathname, location.search);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.pathname !== prevLocation.pathname || location.search !== prevLocation.search) {
      this.sendPageChange(location.pathname, location.search);
    }
  }

  render() {
    return null;
  }
}

const AnalyticsTracker = () => <Route component={Analytics} />;

export default AnalyticsTracker;
