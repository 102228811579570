import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';

export default withOktaAuth(
  class Logout extends Component<IOktaContext> {
    logout = async (): Promise<void> => await this.props.oktaAuth.signOut();

    render(): React.ReactNode {
      return (
        <Button variant="outline-success" onClick={this.logout}>
          Logout
        </Button>
      );
    }
  }
);
