import React from 'react';
import { Table, Form } from 'react-bootstrap';
import _ from 'lodash';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import HasAccess from '../SharedComponents/HasAccess';
import FeatureFlag from '../SharedComponents/FeatureFlag';

export interface NikeDomain {
  domain: string;
  subDomain: string;
}

export interface CostAllocation {
  id: string;
  description: string;
  costCenter: string;
  nikeDomain: NikeDomain;
}

export interface CostAllocationState {}

interface CostAllocationSelectorProps {
  id: string;
  costAllocations: CostAllocation[];
  onChange: (costAllocation: CostAllocation | undefined) => void;
}

const costAllocationsQuery = gql`
  query GetCostAllocations {
    costAllocations {
      objects {
        id
        costCenter
        nikeDomain {
          domain
          subdomain
        }
      }
    }
  }
`;

const withCostAllocations = withGraphQuery(costAllocationsQuery, {
  options: () => ({
    errorPolicy: 'all',
  }),
});

function renderCostAllocation(costAllocation: any): string {
  const rendered = [];
  if (costAllocation.nikeDomain) {
    rendered.push(costAllocation.nikeDomain.domain + '/' + costAllocation.nikeDomain.subdomain);
  }
  if (costAllocation.costCenter) {
    rendered.push('Cost Center: ' + costAllocation.costCenter);
  }
  return rendered.join(' ');
}
class CostAllocationSelectorRenderer extends React.Component<CostAllocationSelectorProps, {}> {
  onChangeHandler(e: any): void {
    const selected = this.props.costAllocations.find(costAllocation => costAllocation.id === e.currentTarget.value);
    this.props.onChange(selected);
  }

  render(): React.ReactNode {
    return (
      <Form.Group controlId="costAllocation">
        <Form.Control as="select" onChange={e => this.onChangeHandler(e)} defaultValue={this.props.id}>
          <option>No cost allocation</option>
          {_.sortBy(this.props.costAllocations, renderCostAllocation).map(costAllocation => {
            return (
              <option key={costAllocation.id} value={costAllocation.id}>
                {renderCostAllocation(costAllocation)}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  }
}

const CostAllocationSelector = withCostAllocations(
  ({
    data: {
      costAllocations: { objects },
    },
    id,
    onChange,
  }: any) => {
    return <CostAllocationSelectorRenderer id={id} costAllocations={objects} onChange={onChange} />;
  }
);

class RenderCostAllocation extends React.Component<CostAllocation, CostAllocationState> {
  renderRow(name: string, path: string): React.ReactNode {
    const value = _.get(this.props, path);
    if (value) {
      return (
        <tr>
          <th>{name}</th>
          <td>{value}</td>
        </tr>
      );
    } else {
      return null;
    }
  }
  render(): React.ReactNode {
    if (this.props.id) {
      return (
        <Table bordered striped>
          <tbody>
            {this.renderRow('Description', 'description')}
            {this.renderRow('Cost Center', 'costCenter')}
            {this.renderRow('Domain', 'nikeDomain.domain')}
            {this.renderRow('Sub Domain', 'nikeDomain.subdomain')}
          </tbody>
        </Table>
      );
    } else {
      return null;
    }
  }
}

type CostAllocationProps = CostAllocation & {
  onChange: (costAllocationId: string) => void;
};

export default class CostAllocationComponent extends React.Component<CostAllocationProps, CostAllocationState> {
  state = {
    costAllocation: this.props,
  };

  onChange(costAllocation: CostAllocation): void {
    this.setState({ costAllocation });
    this.props.onChange(costAllocation.id);
  }

  render(): React.ReactNode {
    return (
      <FeatureFlag feature="costAllocation">
        <>
          <h2 className="account-features-header">Cost Allocation</h2>
          <HasAccess.Body>
            <CostAllocationSelector id={this.props.id} onChange={(e: any) => this.onChange(e)} />
          </HasAccess.Body>
          <RenderCostAllocation {...this.state.costAllocation} />
        </>
      </FeatureFlag>
    );
  }
}
