import _ from 'lodash';

export default function omitByDeep(input, props) {
  function omitDeepOnOwnProps(obj) {
    if (typeof input === 'undefined') {
      return input;
    }

    if (!Array.isArray(obj) && !_.isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitByDeep(obj, props);
    }

    const o = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value !== null) {
        const newValue = !_.isNil(value) ? omitByDeep(value, props) : value;
        if (_.isArray(newValue) || !(_.isObject(newValue) && _.isEmpty(newValue))) {
          o[key] = !_.isNil(value) ? omitByDeep(value, props) : value;
        }
      }
    }

    return _.omit(o, props);
  }

  if (arguments.length > 2) {
    props = Array.prototype.slice.call(arguments).slice(1);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(input);
}
