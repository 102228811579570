import React from 'react';

interface DisabledContactProps {
  active: boolean;
}

export default class DisabledContact extends React.Component<DisabledContactProps, {}> {
  render(): React.ReactNode {
    const { active } = this.props;
    if (!active) {
      return <span className="badge badge-pill badge-danger">Disabled</span>;
    } else {
      return null;
    }
  }
}
