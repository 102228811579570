import React from 'react';
import _ from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import QueryError from './QueryError';
import { graphql } from 'react-apollo';
import { ServicesUnavailable, ServicesUnavailableModal, ServicesUnavailableBanner } from '../ServiceAvailability/ServicesUnavailable';
import { checkAvailability, serviceById } from '../ServiceAvailability';

export interface GraphQLError {
  message: string;
  code?: string;
}

export default function withGraphQuery(query: any, opts: any, errorFilter?: (error: GraphQLError) => boolean): any {
  const serviceValidation = opts.serviceValidation || {};
  const dataCheckersFn = serviceValidation.dataCheckersFn;
  const componentType = serviceValidation.componentType || 'page';
  const withQuery = graphql(query, _.omit(opts, ['serviceValidation']));
  return (callback: any) =>
    withQuery((resp: any) => {
      const {
        data: { loading, error },
      } = resp;
      if (loading) {
        return <Spinner animation="border" variant="secondary" />;
      } else {
        const dataCheckers = dataCheckersFn ? dataCheckersFn(resp) : serviceValidation.dataCheckers || [];
        const { ok, unavailable } = checkAvailability(dataCheckers, resp);
        const unavailableServices = unavailable.map(s => serviceById(s));
        if (ok) {
          return (
            <div>
              <QueryError errors={error} errorFilter={errorFilter} />
              {callback(resp)}
            </div>
          );
        } else if (componentType === 'modal') {
          return (
            <ServicesUnavailableModal services={unavailableServices}>
              <QueryError errors={error} errorFilter={errorFilter} />
              {callback(resp)}
            </ServicesUnavailableModal>
          );
        } else if (componentType === 'banner') {
          return (
            <div>
              <ServicesUnavailableBanner services={unavailableServices}>
                <h1 className="h3">Stuff went wrong</h1>
              </ServicesUnavailableBanner>
              <QueryError errors={error} errorFilter={errorFilter} />
              {callback(resp)}
            </div>
          );
        } else {
          return (
            <ServicesUnavailable services={unavailableServices} />
          );
        }
      }
    });
}
