import React from 'react';

interface Tag {
  key: string;
  value: string;
}
interface TagSet {
  id: string;
  tags: Tag[];
}

interface TagSetProps {
  tagset: TagSet;
  onChange: (value: string | null) => void;
}

interface TagSetState {
  guid: string;
}

export class TagSetView extends React.Component<TagSet> {
  render(): React.ReactNode {
    return (
      <ul>
        {this.props.tags.map(tag => (
          <li key={tag.key}>
            {tag.key}: {tag.value}
          </li>
        ))}
      </ul>
    );
  }
}

export default class TagSetEditor extends React.Component<TagSetProps, TagSetState> {
  state = {
    guid: this.props.tagset.id,
  };

  update = (event: any): void => {
    const newGuid = event.target.value;
    this.setState({ guid: newGuid });
    if (this.props.tagset.id === newGuid) {
      this.props.onChange(null);
    } else {
      this.props.onChange(newGuid);
    }
  };

  render(): React.ReactNode {
    return (
      <div>
        {/* <FormControl value={this.state.guid} onChange={this.update} className="mb-2" /> */}
        <a
          href={process.env.REACT_APP_CLOUDRED_URL+this.state.guid}
          target="_blank"
          rel="noopener noreferrer"
        >{this.state.guid}
        </a>
        <TagSetView {...this.props.tagset} />
      </div>
    );
  }
}
