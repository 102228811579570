import React from 'react';
import { Button, Row, Col, ListGroup } from 'react-bootstrap';
import HasAccess from '../SharedComponents/HasAccess';
import CicdPrincipalInput from './CicdPrincipalInput';

interface CicdPrincipalsInput {
  value: string[];
  partition: string;
  onChange: (value: string[]) => void;
}

interface CicdPrincipalsState {
  principals: string[];
}

export default class CicdPrincipals extends React.Component<CicdPrincipalsInput, CicdPrincipalsState> {
  state = {
    principals: this.props.value || [],
  };

  removeItem = (item: string): void => {
    const newItems = this.state.principals.filter(i => i !== item);
    this.setState({
      principals: newItems,
    });
    this.handleChange(newItems);
  };

  addItem = (brewmasterArn: string): void => {
    const { principals } = this.state;
    const newItems =
      brewmasterArn && !principals.includes(brewmasterArn) ? principals.concat([brewmasterArn]) : principals;
    this.setState({
      principals: newItems,
    });
    this.handleChange(newItems);
  };

  handleChange = (items: string[]): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(items);
    }
  };

  render(): React.ReactNode {
    const { principals } = this.state;
    return (
      <div>
        <HasAccess.Body>
          <CicdPrincipalInput partition={this.props.partition} onAdd={this.addItem} />
        </HasAccess.Body>
        <ListGroup>
          {principals.map(item => (
            <ListGroup.Item key={item} className="no-break-out">
              <Row>
                <Col>{item}</Col>
                <HasAccess.Body>
                  <Col>
                    <Button variant="danger" onClick={() => this.removeItem(item)}>
                      Remove
                    </Button>
                  </Col>
                </HasAccess.Body>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  }
}
