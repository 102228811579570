import React from 'react';
import moment from 'moment';

interface DateProps {
  date: null | string;
}

export default class Date extends React.Component<DateProps> {
  render(): React.ReactNode {
    const { date } = this.props;
    if (date) {
      const parsedDate = moment(date, moment.ISO_8601, true);
      return parsedDate.fromNow();
    } else {
      return null;
    }
  }
}
