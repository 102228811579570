import React from 'react';
import _ from 'lodash';
import { withApollo, WithApolloClient } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { LoggerFactory } from '../../util/LoggerFactory';
import { userSearchQuery, UserSearchResponse } from '../CreateAccount/UserTypeahead';
import './ContactInput.scss';
import { User } from '../CreateAccount/types';

const log = LoggerFactory.getLogger('Contact Input'); // eslint-disable-line

interface ContactInputProps {
  value: string;
  onChange: (value: string) => void;
  client?: ApolloClient<any>;
}

export class ContactInput extends React.Component<WithApolloClient<ContactInputProps>> {
  state = {
    item: this.props.value || '',
    typeaheadOptions: [],
    isLoading: false,
    preventNav: false,
  };

  contactUpdated = (searchString: string): void => {
    this.setState(
      {
        isLoading: true,
        item: searchString,
        preventNav: true,
      },
      () => {
        this.props.client
          .query({
            query: userSearchQuery,
            errorPolicy: 'ignore',
            variables: { searchString },
          })
          .then((response: UserSearchResponse) => {
            const users = _.get(response, ['data', 'users'], []);
            this.setState({
              isLoading: false,
              typeaheadOptions: users,
            });
          });
      }
    );
  };

  updateUser = (user: object): void => {
    const username = _.get(user, 'username', '');
    const newUser: any = {
      email: _.get(user, 'email', ''),
      name: _.get(user, 'name', ''),
      username: _.get(user, 'username', ''),
      primary: _.get(user, 'username', ''),
    };

    this.setState({ item: username }, () => {
      this.props.onChange(newUser);
    });
  };

  render(): React.ReactNode {
    const { item, isLoading, typeaheadOptions } = this.state;

    return (
      <div className="contact-input-container">
        <AsyncTypeahead
          inputProps={{
            className: 'contact-input-typeahead',
          }}
          isLoading={isLoading}
          options={typeaheadOptions}
          onSearch={this.contactUpdated}
          onChange={(selected: User[]) => {
            this.updateUser(selected[0]);
          }}
          labelKey={(o: User) => `${o.username} (${o.name}) - ${o.title}`}
          id={`${item}-typeahead`}
          placeholder={item}
        />
      </div>
    );
  }
}

export const ContactInputWithApollo = withApollo(ContactInput);

export default ContactInputWithApollo;
