import { ContactClient } from './ContactClient';
import { OktaAuth } from '@okta/okta-auth-js';

export class HttpContactClient implements ContactClient {
  auth: OktaAuth;

  constructor(auth: OktaAuth) {
    this.auth = auth;
  }

  saveContact = async (contactId: string, contact: any): Promise<any> => {
    const accessToken = await this.auth.getAccessToken();
    return fetch('/cloudtools/contacts/v1/' + contactId, {
      method: 'PATCH',
      body: JSON.stringify(contact),
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  };
}
