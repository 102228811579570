import React from 'react';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { missingDataFormatter, missingDataClassSelector, defaultSorted } from '../AccountList/AccountsTable';

export const columns = [
  {
    dataField: 'accountId',
    text: 'Account ID',
    formatter: function (cell: string) {
      return <Link to={'/account/' + cell}>{cell}</Link>;
    },
    sort: true,
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: missingDataFormatter,
    classes: missingDataClassSelector,
  },
  {
    dataField: 'accountType',
    text: 'Type',
    sort: true,
    formatter: missingDataFormatter,
    classes: missingDataClassSelector,
  },
  {
    dataField: 'lineage',
    text: 'Lineage',
    sort: true,
  },
  {
    dataField: 'partition',
    text: 'Partition',
    sort: true,
  },
  {
    dataField: 'cloudServiceProvider',
    text: 'Cloud Service Provider',
    sort: true,
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
  },
];
interface RelatedAccountsProps {
  accounts: any;
}
class RelatedAccounts extends React.Component<RelatedAccountsProps, {}> {
  render(): React.ReactNode {
    const { accounts } = this.props;
    if (accounts.length) {
      return (
        <BootstrapTable
          striped
          bootstrap4={true}
          keyField="accountId"
          data={accounts}
          columns={columns}
          defaultSorted={defaultSorted}
        />
      );
    } else {
      return <div>There are no accounts associated with contact.</div>;
    }
  }
}

const contactQuery = gql`
  query GetAccountsForContact($contactId: ID) {
    contact(id: $contactId) {
      accounts {
        accountId
        name
        accountType
        status
        lineage
      }
    }
  }
`;

interface GetContactQueryInput {
  contactId: string;
}

const withContact = withGraphQuery(contactQuery, {
  options: ({ contactId }: GetContactQueryInput) => ({
    errorPolicy: 'all',
    variables: { contactId },
  }),
});

export default withContact(({ data: { contact } }: any) => {
  if (contact) {
    return <RelatedAccounts accounts={contact.accounts} />;
  } else {
    return <span>Contact not found</span>;
  }
});