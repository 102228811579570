import React from 'react';
import Contact from './Contact';
import RelatedAccounts from './RelatedAccounts';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface ContactDisplayProps {
  contactId: string;
  accountId: string;
}

export default class ContactDisplay extends React.Component<ContactDisplayProps, {}> {
  render(): React.ReactNode {
    const { contactId, accountId } = this.props;

    return (
      <div>
        <h1><Link to={`/account/${accountId}`}>{accountId}</Link> - Contact Details</h1>
        <Tabs id="contact-display-tabs" defaultActiveKey="overview">
          <Tab eventKey="overview" title="Overview">
            <Contact contactId={contactId} />
          </Tab>
          <Tab eventKey="related-accounts" title="Related Accounts">
            <RelatedAccounts contactId={contactId} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
