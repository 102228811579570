import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import log from '../util/LoggerFactory';

const httpLink = createHttpLink({
  uri: '/api/graph/v1?timing=true',
});

function authLink(authorizations: any): any {
  return setContext(async (_: any, { headers }: any) => {
    // get the authentication token
    const token = await authorizations.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
}

const errorRetryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 1000 * 60 * 2, // two minutes
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      log.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) log.error(`[Network error]: ${networkError}`);
});

export function ApolloClientConfig(authorizations: any): any {
  return authLink(authorizations)
    .concat(onErrorLink)
    .concat(errorRetryLink)
    .concat(httpLink);
}
