import { action, observable } from 'mobx';

export default class AuthStore {
  @observable
  authenticated: boolean | null = null;

  @observable
  authToken: string | null = null;

  @action.bound
  updateAuthenticated(isAuthenticated: boolean): void {
    this.authenticated = isAuthenticated;
  }

  getAuthToken(): string | null {
    return this.authToken;
  }

  @action.bound
  updateAuthToken(token: string | null): void {
    this.authToken = token;
  }
}
