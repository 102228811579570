import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { AuthTransaction } from '@okta/okta-auth-js/lib/tx';

export default withOktaAuth(
  class Login extends Component<IOktaContext> {
    login = async (): Promise<AuthTransaction> => await this.props.oktaAuth.signIn({});

    render(): React.ReactNode {
      return (
        <Button variant="outline-success" onClick={this.login}>
          Login with Okta
        </Button>
      );
    }
  }
);
