import React from 'react';
import { ListGroup } from 'react-bootstrap';
import JobPercentage from './JobPercentage';
import { JobStep, JobStatusError } from './Job';
import _ from 'lodash';
import './JobStatus.scss';
import HistoryModal from '../SharedComponents/HistoryModal';

interface JobStatusProps {
  jobId: string;
  status: string;
  error: JobStatusError | null;
  steps: JobStep[] | null;
  hideStatus?: boolean;
}

function findVariant(status: string): any {
  switch (status) {
    case 'COMPLETED':
      return 'success';
    case 'PROCESSING':
      return 'warning';
    case 'PENDING':
      return 'info';
    case 'FAILED':
      return 'danger';
    default:
      return 'dark';
  }
}

export function sortSteps(steps: JobStep[]): JobStep[] {
  return _.sortBy(steps, [
    (job: JobStep) => {
      switch (job.status) {
        case 'FAILED':
          return 1;
        case 'PROCESSING':
          return 2;
        case 'PENDING':
          return 3;
        case 'COMPLETED':
          return 4;
        default:
          return 10;
      }
    },
    (job: JobStep) => job.name,
  ]);
}

export function JobStatusTitle(props: JobStatusProps & { openDialog: () => void }): JSX.Element {
  let { status } = props;
  const { hideStatus } = props;

  if ((status === 'COMPLETED'|| status === 'FAILED') && _.get(props, 'error.code')) {
    status = 'FAILED - ' + _.get(props, 'error.code');
  }
  return (
    <div className="job-status" onClick={props.openDialog}>
      {hideStatus ? '' : status}
      <JobPercentage job={props} />
    </div>
  );
}

export default function JobStatus(props: JobStatusProps): JSX.Element {
  const { steps } = props;

  return (
    <HistoryModal
      dialogClassName="job-status-modal"
      queryParamName="stepsJobId"
      queryValue={props.jobId}
      title="Job Steps"
      render={openDialog => <JobStatusTitle openDialog={openDialog} {...props} />}
    >
      <ListGroup>
        {steps &&
          sortSteps(steps).map(step => (
            <ListGroup.Item key={step.name} variant={findVariant(step.status)}>
              {step.name} - {step.status}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </HistoryModal>
  );
}
