import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import gql from 'graphql-tag';
import { stringify } from 'query-string';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { AccountsFilterValues } from './AccountsFilter';
import { AccountsSearchValues } from './AccountsSearch';
import AccountsTable from '../AccountList/AccountsTable';
import './Accounts.scss';
import { LoggerFactory } from '../../util/LoggerFactory';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';

const log = LoggerFactory.getLogger('Accounts');

interface AccountsProps extends RouteComponentProps {}

interface AccountsList {
  accounts: object[];
}

interface ListAccountsQueryResponse {
  data: AccountsList;
}

const GetAllAccountAndContactMetadata = gql`
  query GetAccounts(
    $statuses: [String]
    $accountTypes: [String]
    $lineages: [String]
    $accountSearchTerm: String
    $partitions: [String]
    $cloudServiceProviders: [String]
  ) {
    accounts(
      status: $statuses
      accountType: $accountTypes
      lineage: $lineages
      search: $accountSearchTerm
      partition: $partitions
      cloudServiceProvider: $cloudServiceProviders
    ) {
      accountId
      accountUri
      accountType
      name
      lineage
      status
      partition
      cloudServiceProvider
    }
  }
`;

const withAccounts = withGraphQuery(GetAllAccountAndContactMetadata, {
  options: ({
    accountTypes,
    lineages,
    statuses,
    partitions,
    cloudServiceProviders,
    accountSearchTerm,
  }: AccountsFilterValues & AccountsSearchValues) => ({
    errorPolicy: 'all',
    variables: { accountTypes, lineages, statuses, partitions, accountSearchTerm, cloudServiceProviders },
  }),
});

const AccountsLoader = withAccounts((resp: ListAccountsQueryResponse) => (
  <AccountsTable accounts={resp.data.accounts} />
));

/* queryString does not support unambiguously parsing multiple values
into arrays */
export function extractQueryParams(
  locationSearch: string,
  allowedParams: string[]
): { [key: string]: string | string[] } {
  const givenParams = new URLSearchParams(locationSearch);
  const params = _.intersection(allowedParams, Array.from(givenParams.keys()));
  const result: { [key: string]: string | string[] } = {};
  params.forEach(s => {
    const values = s === 'accountSearchTerm' ? givenParams.get(s) : givenParams.getAll(s);
    // exclude search and other primitives when they're null/empty
    if (values) {
      result[s] = values;
    }
  });
  return result;
}

export function stringifyQueryParams(params: { [key: string]: string | string[] }): string {
  return stringify(
    _(params)
      .omitBy(_.isNil)
      .omitBy(_.isEmpty)
      .value()
  );
}

export default class Accounts extends React.Component<AccountsProps, AccountsFilterValues | AccountsSearchValues> {
  filterDefaults = {
    statuses: [],
    accountTypes: [],
    lineages: [],
    partitions: [],
    cloudServiceProviders: [],
    accountSearchTerm: null,
  };

  allowedFilters = Object.keys(this.filterDefaults);

  state = Object.assign({}, this.filterDefaults, extractQueryParams(this.props.location.search, this.allowedFilters));

  componentWillReceiveProps(nextProps: AccountsProps): void {
    if (nextProps.location !== this.props.location) {
      const existingParams = extractQueryParams(nextProps.location.search, this.allowedFilters);
      const newState = Object.assign({}, this.filterDefaults, existingParams);
      this.setState(newState);
    }
  }

  filterAccounts = (filterValues: AccountsFilterValues | AccountsSearchValues): void => {
    const { location, history } = this.props;
    const existingParams = extractQueryParams(location.search, this.allowedFilters);
    const newParams = Object.assign({}, this.filterDefaults, existingParams, filterValues);
    history.push({ search: stringifyQueryParams(newParams) });
    this.setState(newParams);
  };

  render(): React.ReactNode {
    log.debug('current state = ', this.state);
    return (
      <div className="accounts-container">
        <div className="accounts-header">Accounts</div>
        <AccountsLoader {...this.state} />
      </div>
    );
  }
}
