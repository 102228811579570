import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

export interface LoadingWidgetProps {
  isLoading?: boolean | null | undefined,
  loadingState?: string | null | undefined,
}

export const LoadingWidget: React.FC<LoadingWidgetProps> = ({ isLoading, loadingState }): ReactElement => {
  return (
    <div>
      {loadingState &&
        <p>{loadingState}</p>}
      {isLoading &&
        <Spinner animation="border" role="status">
          <span className="invisible">Loading...</span>
        </Spinner>}
    </div>
  );
}
