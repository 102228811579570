import React from 'react';
import { Card } from 'react-bootstrap';
import './StatsCard.scss';

export interface StatsCardProps {
  title: string
  value: number
}

export default (props: StatsCardProps) => {
  return (
    <Card className="stats-card">
      <Card.Body>
        <h1 className="stats-card-value">{props.value}</h1>
        <Card.Title className="stats-card-title">{props.title}</Card.Title>
      </Card.Body>
    </Card>
  );
};
