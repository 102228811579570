import React, { ReactNode } from 'react';
import './Banner.scss';

export interface BannerProps {
  color?: string;
  children?: ReactNode;
}

export const Banner = ({ children }: BannerProps) => {
  return (
    <div className="waffle-banner w-100 bg-warning d-flex flex-column justify-content-center align-items-center">
      {children}
    </div>
  );
};
