import React from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { AccountTitle } from './AccountTitle';
import CheckForNull from '../SharedComponents/CheckForNull';

// function renderIfAccess(hasAccess: boolean | undefined): React.ReactNode {
//   if (hasAccess) {
//     return <span>Allowed</span>;
//   } else {
//     return <span>Denied</span>;
//   }
// }

interface AccountRole {
  groupName: string;
  owner: string;
  role: string;
}
interface AccountPermission {
  roles: AccountRole[];
  name: string;
  accountType: string;
  permissions: AccountPermissionAcls;
}

interface AccountPermissionAcls {
  acls: Permission[];
}

interface Permission {
  principalId: string;
  principalType: string;
  hasAccess: boolean;
}

interface AccountPermissionsProps {
  account: AccountPermission;
}

export default class AccountPermissions extends React.Component<AccountPermissionsProps, {}> {
  render(): React.ReactNode {
    const { account } = this.props;
    const roles = _.get(account, 'roles', [] as AccountRole[]) || ([] as AccountRole[]);
    // const acls = _.get(account, 'permissions.acls', []) || [];
    return (
      <div>
        <AccountTitle account={account} />
        <h2>Roles</h2>
        These are the roles used to manage access via Okta, and their corresponding AD groups.
        <span className="info">
          Click{' '}
          <a href="https://confluence.nike.com/display/NDAM/Waffle+Iron+-+Get+Started#WaffleIronGetStarted-UserManagement" target="_blank" rel="noopener noreferrer">
            here
          </a>{' '}
          for more information about how to view/manage membership of the AD groups
        </span>
        <CheckForNull target={account} field={['roles']} errorMessage="Unable to load roles">
          <Table bordered striped>
            <thead>
              <tr>
                <th>RAM Role Name</th>
                <th>AD Group Name</th>
              </tr>
            </thead>
            <tbody>
              {roles.map(role => (
                <tr key={role.role}>
                  <td>{role.role}</td>
                  <td>
                    <a
                      href={
                        'https://idlocker.nike.com/identityiq/ui/rest/redirect?rp1=/accessRequest/accessRequest.jsf&rp2=accessRequest/manageAccess/add?filterKeyword=' +
                        encodeURIComponent(role.groupName)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {role.groupName}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CheckForNull>
        {/* <h2 className="mt-4">Permissions</h2>
        This controls access to manage the account via Waffle Iron; not access within the account.
        <CheckForNull target={account} field={'permissions.acls'} errorMessage="Unable to load permissions">
          <Table bordered striped>
            <thead>
              <tr>
                <th>PrincipalId</th>
                <th>PrincipalType</th>
                <th>Has Access</th>
              </tr>
            </thead>
            <tbody>
              {acls.map((acl: any) => (
                <tr key={acl.principalType + acl.principalId}>
                  <td>{acl.principalId}</td>
                  <td>{acl.principalType}</td>
                  <td>{renderIfAccess(acl.hasAccess)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CheckForNull> */}
      </div>
    );
  }
}
