import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './MissingAttribute.scss';

interface MissingAttributeProps {
  content?: string;
}

export default class MissingAttribute extends Component<MissingAttributeProps, {}> {
  render(): React.ReactNode {
    const { content } = this.props;
    return (
      <div>
        <FontAwesomeIcon className="data-missing-icon" icon={faExclamationTriangle} color="black" />
        {content || 'Missing'}
      </div>
    );
  }
}

export function renderTableData(content: any, missingDataMessage?: string): React.ReactNode {
  return (
    (content && <td>{content}</td>) || (
      <td className="warning-table-cell">
        <MissingAttribute content={missingDataMessage} />
      </td>
    )
  );
}
