import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';

interface HistoryModalProps {
  queryParamName: string;
  queryValue: string;
  title: string;
  dialogClassName?: string;

  render: (open: () => void) => JSX.Element;
}

const HistoryModal: React.FunctionComponent<HistoryModalProps> = (props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const getShow = useCallback((): boolean => {
    const params = new URLSearchParams(location.search);
    const doShow = params.get(props.queryParamName);
    return doShow ? doShow === props.queryValue : false;
  }, [location, props]);

  const [show, setShow] = useState(getShow());

  useEffect(() => {
    setShow(getShow());
  }, [setShow, getShow]);

  const handleClose = (): void => {
    const params = new URLSearchParams(location.search);
    params.delete(props.queryParamName);
    history.push({ search: params.toString() });
    setShow(false);
  };

  const openDialog = (): void => {
    const params = new URLSearchParams(location.search);
    params.set(props.queryParamName, props.queryValue);
    history.push({ search: params.toString() });
    setShow(true);
  };

  return (
    <div>
      <div>{props.render(openDialog)}</div>
      <Modal show={show} dialogClassName={props.dialogClassName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HistoryModal;
