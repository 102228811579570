import React from 'react';
import gql from 'graphql-tag';
import withGraphQuery from '../../GraphQuery/GraphQuery';
import DeletionDetailTable from './DeletionDetailsTable';
import { LoggerFactory } from '../../../util/LoggerFactory';

const log = LoggerFactory.getLogger('Deletion Detail');

const deletionDetailQuery = gql`
  query GetDeletionDetail($jobId: String) {
    accountJob(jobId: $jobId) {
      status
      account {
        accountType
      }
      deleteResourceScan {
        failedToDeleteResources {
          name
          reason
          region
          type
        }
        ignoredResources {
          name
          region
          type
        }
        deletedResources {
          name
          region
          type
        }
        resources {
          name
          region
          type
        }
      }
    }
  }
`;

interface GetDeletionDetailInput {
  jobId: string;
}

const withDeletionDetail = withGraphQuery(deletionDetailQuery, {
  options: ({ jobId }: GetDeletionDetailInput) => ({
    errorPolicy: 'all',
    variables: { jobId },
  }),
});

export default withDeletionDetail(({ data: { accountJob, startPolling, stopPolling } }: any) => {
  if (accountJob) {
    if (accountJob.status !== 'COMPLETED') {
      log.debug('starting polling');
      startPolling(500);
    } else {
      stopPolling();
    }
    return <DeletionDetailTable {...accountJob.deleteResourceScan} accountType={accountJob.account.accountType} />;
  } else {
    return <h1>Not Found</h1>;
  }
});
