import React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { LoggerFactory } from '../../util/LoggerFactory';
import CreateAccountStatusTablePure from './CreateAccountStatusTable';

const log = LoggerFactory.getLogger('My Requests');

//TODO: consolidate the createAccountStatuses and the createJobs query/schema
const createAccountStatusesQuery = gql`
  query CreateAccountStatuses {
    currentUser {
      createJobs {
        jobId
        status
        request {
          contact {
            id
          }
          tagset {
            id
          }
        }
        response {
          accountId
        }
        error {
          code
        }
        steps {
          name
          status
        }
      }
    }
  }
`;

function hasCompleted(jobStatus: any): boolean {
  return jobStatus.status === 'COMPLETED' || jobStatus.status === 'FAILED';
}

export function convertJob(job: any): any {
  const errors = [];
  if (job.error && job.error.code) {
    errors.push({
      component: 'account',
      message: job.error.code,
    });
  }
  return {
    jobId: job.jobId,
    createAccountJobId: job.jobId,
    status: job.status,
    steps: job.steps,
    accountId: _.get(job, 'response.accountId'),
    contactId: _.get(job, 'request.contact.id'),
    tagsetId: _.get(job, 'request.tagset.id'),
    error: job.error,
    errors: errors,
  };
}

const CreateAccountStatusTable = withGraphQuery(createAccountStatusesQuery, {
  options: () => {
    return {
      errorPolicy: 'all',
    };
  },
})((graphqlResponse: any) => {
  const { data } = graphqlResponse;
  const { startPolling, stopPolling } = data;

  log.info(data);
  if (!data.currentUser.createJobs.every(hasCompleted)) {
    startPolling(2000);
  } else {
    stopPolling();
  }

  return <CreateAccountStatusTablePure jobStatuses={data.currentUser.createJobs.map(convertJob)} />;
});

export class CreateAccountStatusPage extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return <CreateAccountStatusTable />;
  }
}

export default CreateAccountStatusPage;
