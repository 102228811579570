import React from 'react';

const features = ['features', 'costAllocation', 'my-accounts'];

function featureName(name: string): string {
  return 'waffleiron.' + name;
}

interface Feature {
  name: string;
  value: boolean;
}

export function listFeatures(): Feature[] {
  const here = features.map(featureName => {
    return {
      name: featureName,
      value: getFeature(featureName),
    };
  });
  return here;
}

export function setFeature(feature: string, enabled: boolean): void {
  localStorage.setItem(featureName(feature), enabled ? 'true' : 'false');
}

export function getFeature(feature: string): boolean {
  const value = localStorage.getItem(featureName(feature));
  if (value === 'true') {
    return true;
  } else {
    return false;
  }
}

export interface FeatureFlagProps {
  feature: string;
}

export default class FeatureFlag extends React.Component<FeatureFlagProps> {
  render(): React.ReactNode {
    if (getFeature(this.props.feature)) {
      return <div>{this.props.children}</div>;
    } else {
      return null;
    }
  }
}
