import React from 'react';
import { Subtract } from 'utility-types';
import { LoggerFactory } from '../../util/LoggerFactory';

const log = LoggerFactory.getLogger('Account Client');

export interface AccountClient {
  saveAccount(accountId: string, account: any): Promise<any>;
  requestAccountDeletion(accountId: string): Promise<any>;
}

const AccountClientContext = React.createContext<AccountClient | null>(null);

export interface WithAccountClientProps {
  accountClient: AccountClient;
}

export default class AccountClientProvider extends React.Component<WithAccountClientProps> {
  render(): React.ReactNode {
    return (
      <AccountClientContext.Provider value={this.props.accountClient}>
        {this.props.children}
      </AccountClientContext.Provider>
    );
  }
}

export const withAccountClient = <P extends WithAccountClientProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Subtract<P, WithAccountClientProps>> => {
  class WithAccountClient extends React.Component<Subtract<P, WithAccountClientProps>> {
    static contextType = AccountClientContext;

    render(): React.ReactNode {
      const accountClient: AccountClient = this.context;
      log.debug('accountClient', accountClient);
      if (accountClient === undefined) {
        throw new Error('no accountClient defined');
      }
      const props = this.props as P;
      return <Component {...props} accountClient={accountClient} />;
    }
  }
  return WithAccountClient;
};
