import React from 'react';
import { TagValidationResponse } from './types';

export interface ValidationProps {
  validationData: TagValidationResponse | undefined | null;
}

export function ValidationErrors({ validationData }: ValidationProps) {
  if (validationData && validationData.status !== "pass") {
    const { message } = validationData;
    return (
      <div className="invalid-feedback d-block">
        <ul>
          {message && message.map((s: string) => {
            return (<li key={s}>{s}</li>);
          })}
        </ul>
      </div>
    ) as JSX.Element;
  } else {
    return <div></div>;
  }
}
