import React from 'react';
import Table from 'react-bootstrap/Table';
import _ from 'lodash';
import HasAccess from '../SharedComponents/HasAccess';
import EnableFeatureButton from './EnableFeatureButton';
import CicdPrincipals from './CicdPrincipals';
import omitByDeep from '../../util/omitByDeep';
import { LoggerFactory } from '../../util/LoggerFactory';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './FeatureEditor.scss';
import PilotFeatureButton from './PilotFeature';

const log = LoggerFactory.getLogger('Feature Editor');

interface FeatureEnabledProps {
  value: boolean;
  id: string;
  onChange: (value: boolean | null) => void;
  disabledBtn?: boolean;
  toggleTerraform?: boolean;
}

export class FeatureEnabled extends React.Component<FeatureEnabledProps, {}> {
  state = {
    selected: this.props.value,
  };

  render(): React.ReactNode {
    const { value, onChange, disabledBtn, toggleTerraform } = this.props;

    if (value) {
      return 'Enabled';
    } else {
      return (
        <div>
          <HasAccess.NoAccess>Disabled</HasAccess.NoAccess>
          <HasAccess.Body>
            <EnableFeatureButton onChange={onChange} toggleTerraform={toggleTerraform} disabledBtn={disabledBtn} />
          </HasAccess.Body>
        </div>
      );
    }
  }
}

interface FeatureEditorProps {
  features: any;
  partition: string;
  onChange: (value: any) => void;
  disabledFeatures?: string[];
}

export function doMerge(original: object, map: object): object {
  const cloned = _.cloneDeep(original);
  const mutatedMap = _.mergeWith(cloned, _.cloneDeep(map), (objValue, srcValue) => {
    //the default lodash merge merges array contents
    if (_.isArray(srcValue)) {
      return srcValue;
    } else if (_.isObject(srcValue)) {
      return doMerge(objValue, srcValue);
    } else {
      return srcValue;
    }
  });
  log.debug(mutatedMap);
  return omitByDeep(mutatedMap, _.isNull);
}

export default class FeatureEditor extends React.Component<FeatureEditorProps> {
  state = {
    enableTF: true,
    features: this.props.features,
    toggleTerraform: true,
  };

  updateFeature = (updatedFeatures: object): void => {
    let newFeatures: any = { cicd: { brewmasters: '', terraform: false } };
    newFeatures = doMerge(this.state.features, updatedFeatures);
    if (newFeatures?.cicd?.brewmasters?.length === 0) {
      newFeatures.cicd.terraform = false;
      this.setState({ toggleTerraform: false });
    } else {
      this.setState({ toggleTerraform: true });
    }
    this.setState({ features: newFeatures });
    const checkBrew = _.get(updatedFeatures, ['cicd', 'brewmasters'], false);
    if (checkBrew) {
      this.setState({ enableTF: checkBrew.length === 0 ? true : false });
    }
    return this.props.onChange(newFeatures);
  };

  render(): React.ReactNode {
    const disabledFeatures = this.props.disabledFeatures || [];
    const { partition } = this.props;
    const { enableTF } = this.state;
    return (
      <Table striped bordered>
        <tbody>
          <tr>
            <th>
              <a
                href="https://confluence.nike.com/display/NDAM/Waffle+Iron+FAQ#WaffleIronFAQ-HowdoIenableBMXintegrationinmyWaffleaccount"
                target="_blank"
                rel="noopener noreferrer"
              >
                CI/CD Integration
              </a>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props: any) => (
                  <Tooltip className="feature-tooltip" {...props}>
                    To allow BMX to assume the `NIKE.cicd.tool` IAM role, input your BMX name (ex: ecg). <br />
                    To allow a non-BMX IAM Principal, input the full IAM Principal ARN.
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
              </OverlayTrigger>
              <br />
              <br />
              {partition === 'CHINA' && (
                <p className="font-weight-normal">
                  <span className="font-weight-bold">Note:</span> BMX users must have a&nbsp;
                  <a href="https://bmx.auto.nikecloud.com/getting_started/agent-groups/#how-to-configure-an-agent-group-in-china">
                    BMX Agent Group in China
                  </a>
                </p>
              )}
            </th>
            <td>
              <CicdPrincipals
                partition={this.props.partition}
                value={_.get(this.props, ['features', 'cicd', 'brewmasters'], [])}
                onChange={(value: string[]) => this.updateFeature({ cicd: { brewmasters: value } })}
              />
            </td>
          </tr>
          <tr>
            <th>
              <a
                href="https://github.com/nike-waffle/wafflebar-cookbooks/blob/master/terraform-integration.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terraform Integration
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props: any) => (
                    <Tooltip className="feature-tooltip" {...props}>
                      Builds out S3 bucket and DynamoDB for terraform state locking, as well as permissions on CICD
                      roles
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                </OverlayTrigger>
              </a>
            </th>
            <td>
              <FeatureEnabled
                id="terraform"
                value={_.get(this.props, ['features', 'cicd', 'terraform'], false)}
                toggleTerraform={this.state.toggleTerraform}
                disabledBtn={enableTF}
                onChange={(value) => this.updateFeature({ cicd: { terraform: value } })}
              />
            </td>
          </tr>
          {!disabledFeatures.includes('route53') && (
            <tr>
              <th>
                <a
                  href="https://confluence.nike.com/display/NDAM/Waffle+Iron+FAQ#WaffleIronFAQ-HowdoIgetadelegatedDNSdomain%3Caccountname%3E-%3Cenvironment%3E.nikecloud.comprovisionedformyWaffleaccount"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NikeCloud Subdomain
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props: any) => (
                      <Tooltip className="feature-tooltip" {...props}>
                        By enabling this feature, the account will have a route53 delegated hosted zone of the form{' '}
                        {'accountName'}-{'accountType'}.nikecloud.com
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                  </OverlayTrigger>
                </a>
              </th>
              <td>
                <FeatureEnabled
                  id="nikecloud"
                  value={_.get(this.props, ['features', 'route53', 'nikecloud'], false)}
                  onChange={(value) => this.updateFeature({ route53: { nikecloud: value } })}
                />
              </td>
            </tr>
          )}
          <tr>
            <th>
              <a href="https://confluence.nike.com/display/NDAM/Waffle+Bar" target="_blank" rel="noopener noreferrer">
                WaffleBar Cloudformation Transform
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props: any) => (
                    <Tooltip className="feature-tooltip" {...props}>
                      Waffle Bar is a framework that enables account holders to provision AWS resources that are
                      compliant with cloud and security best practices. Waffle Bar will contain community contributed
                      and curated patterns (a.k.a. "Toppings") which can be applied to Waffle Iron provisioned AWS
                      accounts (a.k.a. "Waffles").
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                </OverlayTrigger>
              </a>
            </th>
            <td>
              <FeatureEnabled
                id="wafflebar"
                value={_.get(this.props, ['features', 'serviceCatalog', 'wafflebar'], false)}
                onChange={(value) => this.updateFeature({ serviceCatalog: { wafflebar: value } })}
              />
            </td>
          </tr>
          <tr>
            <th>
              <a
                href="https://confluence.nike.com/display/NDAM/Product+Development+Workflow"
                target="_blank"
                rel="noopener noreferrer"
              >
                Service Catalog Integration
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props: any) => (
                    <Tooltip className="feature-tooltip" {...props}>
                      This feature enables access to deploy curated AWS Service Catalog portfolios and products in a
                      Waffle Iron account
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                </OverlayTrigger>
              </a>
            </th>
            <td>
              <FeatureEnabled
                id="awsServiceCatalog"
                value={_.get(this.props, ['features', 'serviceCatalog', 'enabled'], false)}
                onChange={(value) => this.updateFeature({ serviceCatalog: { enabled: value } })}
              />
            </td>
          </tr>
          {!disabledFeatures.includes('nikePlatformEngine') && (
            <tr>
              <th>
                <a href="https://docs.platforms.nike.com/NPE" target="_blank" rel="noopener noreferrer">
                  Nike Platform Engine
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props: any) => (
                      <Tooltip className="feature-tooltip" {...props}>
                        Nike Platform Engine (NPE) is a Service Platform (SP) offering. NPE primary role is to simplify
                        the management and operations of composable micro-services.
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                  </OverlayTrigger>
                </a>
              </th>
              <td>
                <FeatureEnabled
                  id="nikePlatformEngine"
                  value={_.get(this.props, ['features', 'nikePlatformEngine', 'enabled'], false)}
                  onChange={(value) => this.updateFeature({ nikePlatformEngine: { enabled: value } })}
                />
              </td>
            </tr>
          )}
          {!disabledFeatures.includes('nikeMultiCloudNetwork') && (
            <tr>
              <th>
                <a
                  href="https://confluence.nike.com/display/ISO/Aviatrix+Multi-Cloud+Network+Infrastructure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nike Multi Cloud Network
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props: any) => (
                      <Tooltip className="feature-tooltip" {...props}>
                        Deploy the solution to route and secure traffic using an Aviatrix controller and gateway based
                        solution, which allows the supportability to manage network in cloud.
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                  </OverlayTrigger>
                </a>
              </th>
              <td>
                <FeatureEnabled
                  id="nikeMultiCloudNetwork"
                  value={_.get(this.props, ['features', 'nikeMultiCloudNetwork', 'enabled'], false)}
                  onChange={(value) => this.updateFeature({ nikeMultiCloudNetwork: { enabled: value } })}
                />
              </td>
            </tr>
          )}
          <tr>
            <th>
              <a href="https://confluence.nike.com/x/NLRcIw" target="_blank" rel="noopener noreferrer">
                Restricted PII Handler SSO
              </a>
            </th>
            <td>
              <PilotFeatureButton featureEnabled={_.get(this.props, ['features', 'piiHandlerSso', 'enabled'], false)} />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}
