import React from 'react';
import { InputGroup, FormControl, Button, DropdownButton, Dropdown } from 'react-bootstrap';

interface CicdPrincipalInputProps {
  onAdd: (value: string) => void;
  partition: string;
}

export interface CicdPrincipalInputState {
  newItem: string;
  isBmx: boolean;
}

const globalBmxPrefix = 'arn:aws:iam::046979685931:role/brewmaster/base/brewmaster-base-';
const chinaBmxPrefix = 'arn:aws-cn:iam::108851027208:role/brewmaster-base-';

export default class CicdPrincipal extends React.Component<CicdPrincipalInputProps, CicdPrincipalInputState> {
  state = {
    newItem: '',
    isBmx: true,
  };

  bmxPrefix = this.props.partition === 'GLOBAL' ? globalBmxPrefix : chinaBmxPrefix;

  // react-bootstrap FormControl has a bug with their onChange type signature.  Using any.
  itemUpdated = (event: any): void => {
    this.setState({
      newItem: event.target.value,
    });
  };

  onAdd = (): void => {
    const { isBmx, newItem } = this.state;
    if (newItem) {
      const toAdd = isBmx ? this.bmxPrefix + newItem : newItem;
      this.props.onAdd(toAdd);
      this.setState({
        newItem: '',
      });
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onAdd();
    }
  };

  onSelect = (eventKey: string | null): void => {
    if (eventKey === 'bmx') {
      this.setState({
        isBmx: true,
      });
    } else {
      this.setState({
        isBmx: false,
      });
    }
  };

  render(): React.ReactNode {
    const { newItem, isBmx } = this.state;

    return (
      <div>
        {isBmx && (
          <label htmlFor="bmx-name" className="no-break-out">
            {this.bmxPrefix + newItem}
          </label>
        )}
        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <DropdownButton
              variant="primary"
              title={isBmx ? 'BMX Name' : 'IAM Principal ARN'}
              id="input-group-dropdown-1"
            >
              <Dropdown.Item eventKey="bmx" onSelect={this.onSelect}>
                BMX Name
              </Dropdown.Item>
              <Dropdown.Item eventKey="other" onSelect={this.onSelect}>
                IAM Principal ARN
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup.Prepend>
          <FormControl onChange={this.itemUpdated} value={newItem} onKeyDown={this.onKeyDown} />
          <InputGroup.Append>
            <Button variant="success" onClick={this.onAdd} className="ml-0">
              Add
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }
}
