import React, { ChangeEvent, ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead, TypeaheadModel } from 'react-bootstrap-typeahead';
import { TagKeyPair, TagOption, TagValueProps } from './types';

export function asTagOption(tag: TagKeyPair): TagOption {
  return {
    id: tag.value || '',
    value: tag.label || tag.value || '',
  };
}

export function isTagOption(val: TagOption | string | undefined): val is TagOption {
  return (val as TagOption) !== undefined;
}

export function optionValue(value: TagOption | string | undefined): string | undefined {
  return (value && isTagOption(value)) ? value.id : value;
}

export function isIllegal(illegalStrings: string[] | undefined, s: string | undefined): boolean {
  if (!illegalStrings) {
    return false;
  } else {
    return illegalStrings.includes(s as string);
  }
}

export const withValue = (tag: TagKeyPair, value: string | undefined, label: string | undefined | null): TagKeyPair => {
  return {
    ...tag,
    value: value,
    label: label || value,
  };
}

export const isInvalid = ({ validate, errors }: TagValueProps): boolean => {
  return !!validate && !!errors && !!errors.length;
}

export const isValid = (props: TagValueProps): boolean => {
  return !!props.validate && !isInvalid(props);
}

export const OptionListTag: React.FC<TagValueProps> = (props): ReactElement => {
  const { rule, tag, errors, onChange, onBlur, validate } = props;
  const handleChange = (selectedOption: TagOption | undefined) => {
    if (onChange) {
      if (!selectedOption) {
        onChange(withValue(tag, undefined, null));
      } else {
        onChange(withValue(tag, selectedOption.id, selectedOption.value));
      }
    }
  };
  return (
    <Form.Group>
      <Typeahead
        clearButton
        id={`{rule.tag_key}-select`}
        options={rule.option_list as TagOption[]}
        labelKey="value"
        onChange={(selected: TypeaheadModel[]) => {
          handleChange(selected[0] as TagOption);
        }}
        isInvalid={isInvalid(props)}
        isValid={isValid(props)}
        onBlur={onBlur}
      />
      {validate && errors && errors.map((e: string) => (
        <Form.Control.Feedback key={e} type="invalid" className="d-block">{e}</Form.Control.Feedback>
      ))}
    </Form.Group>
  );
}

export const ValueListTag: React.FC<TagValueProps> = (props): ReactElement => {
  const { rule, tag, errors, onChange, onBlur, validate } = props;
  const handleChange = (selectedValue: string | undefined) => {
    if (onChange) {
      if (!selectedValue) {
        onChange(withValue(tag, '', null));
      } else {
        onChange(withValue(tag, selectedValue, null));
      }
    }
  };
  return (
    <Form.Group>
      <Typeahead
        clearButton
        id={`{rule.tag_key}-select`}
        options={rule.value_list as string[]}
        selected={tag.value ? [tag.value] : []}
        onChange={(selected: string[]) => {
          handleChange(selected[0]);
        }}
        isInvalid={isInvalid(props)}
        isValid={isValid(props)}
        onBlur={onBlur}
      />
      {validate && errors && errors.map((e: string) => (
        <Form.Control.Feedback key={e} type="invalid" className="d-block">{e}</Form.Control.Feedback>
      ))}
    </Form.Group>
  );
}

export const TextTag: React.FC<TagValueProps> = (props): ReactElement => {
  const { tag, onChange, errors, onBlur, validate } = props;
  const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(withValue(tag, e.target.value, null));
    }
  };
  return (
    <Form.Group>
      <Form.Control
        type="text"
        value={(tag && tag.value) || ''}
        onChange={(evt: any) => handleChangeEvent(evt as ChangeEvent<HTMLInputElement>)}
        isInvalid={isInvalid(props)}
        isValid={isValid(props)}
        onBlur={onBlur}
      />
      {validate && errors && errors.map((e: string) => (
        <Form.Control.Feedback key={e} type="invalid">{e}</Form.Control.Feedback>
      ))}
    </Form.Group>
  );
}

export const TagValue: React.FC<TagValueProps> = (props): ReactElement => {
  const { rule } = props;
  const ValueRenderer = rule.option_list ? OptionListTag : (rule.value_list ? ValueListTag : TextTag);
  return (
    <ValueRenderer {...props} />
  );
};
