import React from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import { Job } from './Job';
import { LoggerFactory } from '../../util/LoggerFactory';

const log = LoggerFactory.getLogger('Job Percentage');

export function jobPercentage(job: Job | null): number | null {
  if (!job || job.steps === undefined || job.steps === null) {
    return null;
  }
  const allSteps = job.steps;
  const processing = _.filter(allSteps, x => x.status === 'PROCESSING' || x.status === 'PENDING');
  log.debug({ processing: processing.length, allSteps: allSteps.length });
  if (allSteps.length) {
    return _.round(((allSteps.length - processing.length) / allSteps.length) * 100);
  } else {
    return null;
  }
}

interface JobPercentageProps {
  job: Job | null;
}

export default function JobPercentage(props: JobPercentageProps): React.ReactElement | null {
  if (!props.job) {
    return null;
  }
  const percentage = jobPercentage(props.job);
  log.debug('found percentage: ' + percentage);
  if (percentage === null) {
    return <Spinner animation="border" variant="secondary" />;
  } else if (percentage === 100) {
    return <ProgressBar now={100} variant="success" />;
  } else {
    return (
      <ProgressBar
        now={percentage}
        label={`${percentage}%`}
        variant={_.get(props.job, 'error.code') ? 'danger' : 'info'}
        striped
      />
    );
  }
}
