import React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import withGraphQuery from '../GraphQuery/GraphQuery';
import { Tab, Tabs } from 'react-bootstrap';
import AccountEditor from './AccountEditor';
import AccountPermissions from './AccountPermissions';
import AccountJobs from './AccountJobs';
import { filterNonNullable } from '../../util/filterQueryErrors';
import { LoggerFactory } from '../../util/LoggerFactory';
import '../../styles/shared.scss';
import { useLocation, useHistory } from 'react-router';
import * as H from 'history';
import { stringify } from 'query-string';
import * as services from '../ServiceAvailability/services';

const log = LoggerFactory.getLogger('Account Detail');

const waffleAwsCheckers = [
  {
    serviceName: services.WAFFLE_API,
    dataPath: ['account', 'lineage'],
  },
  {
    serviceName: services.CONTACTS_API,
    dataPath: ['account', 'contact', 'id'],
  },
  {
    serviceName: services.LDAP,
    dataPath: ['account', 'contact', 'technical', 'primary', 'name'],
  },
  {
    serviceName: services.CLOUDRED,
    dataPath: ['account', 'tagset', 'tags'],
  },
];

function findDataCheckers(graphResponse: any) {
  const lineage = _.get(graphResponse, ['data', 'account', 'lineage']);
  const csp = _.get(graphResponse, ['data', 'account', 'cloudServiceProvider']);

  if (lineage === 'WAFFLE_IRON' && csp === 'AWS') {
    return waffleAwsCheckers;
  } else {
    // Only check data for standard waffle accounts for now
    return [];
  }
}

const accountQuery = gql`
  query GetAccount($accountId: ID) {
    account(accountId: $accountId) {
      name
      accountId
      accountType
      status
      partition
      creationDate
      lineage
      updatedDate
      cloudServiceProvider
      expirationDate
      terminationProtection
      costAllocation {
        id
        description
        costCenter
        nikeDomain {
          domain
          subdomain
        }
      }
      contact {
        id
        active
        billing {
          primary {
            name
          }
        }
        business {
          primary {
            name
          }
        }
        security {
          primary {
            name
          }
        }
        technical {
          primary {
            name
          }
        }
      }
      tagset {
        id
        tags {
          key
          value
        }
      }
      currentJobs: jobs(status: ["PROCESSING", "PENDING"]) {
        jobId
        status
        type
        startDate
        endDate
        steps {
          name
          status
        }
      }
      jobs {
        jobId
        status
        type
        startDate
        endDate
        steps {
          name
          status
        }
        error {
          code
        }
      }
      features {
        cicd {
          brewmasters
          terraform
        }
        route53 {
          nikecloud
        }
        serviceCatalog {
          wafflebar
          enabled
        }
        nikePlatformEngine {
          enabled
        }
        nikeMultiCloudNetwork {
          enabled
        }
        piiHandlerSso {
          enabled
        }
      }
      roles {
        role
        groupName
        owner
      }
      permissions {
        hasAccess
        acls {
          hasAccess
          principalId
          principalType
        }
      }
    }
  }
`;

interface GetAccountQueryInput {
  accountId: string;
}

const withAccount = withGraphQuery(
  accountQuery,
  {
    serviceValidation: {
      dataCheckersFn: findDataCheckers,
      componentType: 'banner',
    },
    options: ({ accountId }: GetAccountQueryInput) => ({
      errorPolicy: 'all',
      variables: { accountId },
    }),
  },
  filterNonNullable
);

interface AccountViewProps {
  account: any;
  refresh: () => void;
  location: H.Location<H.LocationState>;
  history: H.History;
}

interface AccountViewState {
  currentTab: string;
}

class AccountView extends React.Component<AccountViewProps, AccountViewState> {
  defaultTab: string = 'overview';
  tabQueryParamKey: string = 'tab';

  state = {
    currentTab: this.defaultTab,
  };

  getTab(location: string): string {
    const params = new URLSearchParams(location);
    const tab = params.get(this.tabQueryParamKey);
    return tab ? tab : this.defaultTab;
  }

  updateTab = (key: string | null): void => {
    const { history } = this.props;
    const newParams: { [key: string]: string } = {};
    newParams[this.tabQueryParamKey] = key as string;

    history.push({ search: stringify(newParams) });
    this.setState({ currentTab: key as string });
  };

  // handle forward and back button in browser
  componentDidUpdate(nextProps: AccountViewProps): void {
    if (nextProps.location !== this.props.location) {
      this.setState({ currentTab: this.getTab(nextProps.location.search) });
    }
  }

  render(): React.ReactNode {
    const { account, refresh, location } = this.props;
    const currentTab = this.getTab(location.search);

    return (
      <Tabs
        id="account-overview-tabs"
        activeKey={['overview', 'permissions', 'eventHistory'].includes(currentTab) ? currentTab : this.defaultTab}
        onSelect={this.updateTab}
      >
        <Tab eventKey="overview" title="Overview">
          <div className="content-container">
            <AccountEditor account={account} refresh={refresh} />
          </div>
        </Tab>
        <Tab eventKey="permissions" title="Permissions">
          <div className="content-container">
            <AccountPermissions account={account} />
          </div>
        </Tab>
        <Tab eventKey="eventHistory" title="Event History">
          <div className="content-container">
            <AccountJobs account={account} />
          </div>
        </Tab>
      </Tabs>
    );
  }
}

interface WithAccountOptions {
  account: any;
  refetch: () => void;
  startPolling: (ms: number) => void;
  stopPolling: () => void;
}

interface WithAccountInput {
  data: WithAccountOptions;
}

export default withAccount(({ data: { account, refetch, startPolling, stopPolling } }: WithAccountInput) => {
  const location = useLocation();
  const history = useHistory();
  const pendingStatuses = ['UPDATING', 'DELETING'];
  if (account) {
    if (pendingStatuses.includes(account.status) || (account.currentJobs && account.currentJobs.length)) {
      log.debug('starting polling');
      startPolling(500);
    } else {
      stopPolling();
    }
    return <AccountView account={account} refresh={refetch} location={location} history={history} />;
  } else {
    return <h1>Not Found</h1>;
  }
});
